<template>
    <div class="mainbookinggen v-overflow-booking" style="">

        <div class="cont-tables booking_gen" style=" ">

            <div class="spin">
                <ion-spinner color="primary" name="lines-small" v-pre></ion-spinner>
            </div>

            <div class="row cont_booking_rsa cont_book_pasq2">



            </div>

            <div class="buttons">
                <div class="con" style="max-width: 100px;
            text-align: center;">
                </div>
            </div>

        </div>

        <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
            aria-hidden="true" style="    top: -519px;
                display: none;
                margin: 0px auto;
                border: none;
                padding: 0;
                box-shadow: none;
                background:none;
                max-width:420px!important">
            <div class="modal-dialog" role="document" style="min-width:400px;">
                <div class="modal-content">
                    <div class="modal-header" style="padding:3px 3px 3px 3px!important">
                        <h5 class="modal-title title-table" id="exampleModalLabel" style="padding-top:7px">Modal title
                        </h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                            style="display:none">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div
                        style="width:100%;height:30px;background-color:#96959b!important;background-image: linear-gradient(to right,#e0dee6 , #96959b)">
                        &nbsp;</div>
                    <div class="itemselected" style="width:100%;height:17px;padding-left:13px;padding-top:5px"></div>

                    <div class="projectsassociated modal-body"
                        style="max-height:150px;overflow-y: auto;min-height: 150px;">
                    </div>
                    <div class="modal-footer">
                        <img style="margin: 0 auto;cursor: pointer" class="submit-condition"
                            src="https://app.smart-book.it/_lib/img/usr__NM__icon_ok_32.png" />
                    </div>
                </div>
            </div>
        </div>



        <div class="contpopupdate" style="display:none">

            <template>
                <modal name="popupDate" :clickToClose="false" :width="300" :height="254">

                    <v-row style="margin-bottom: 0px!important;padding-bottom: 0px!important;">

                        <v-col cols="12" md="12"
                            style="display: flex;margin-bottom: 0px!important;padding-bottom: 0px!important;">

                            <div style="border-left: 5px solid #c39d63;
border-top: 5px solid #c39d63;
width: 30px;height: 30px;background-color: black;">&nbsp;</div>

                            <div style="width:240px;height:30px;background-color: black;">&nbsp;</div>

                            <div style="border-right: 5px solid #c39d63;
border-top: 5px solid #c39d63;
width: 30px;height: 30px;background-color: black;">&nbsp;</div>


                        </v-col>

                    </v-row>

                    <v-row style="background-color: black;margin-top:0px!important;padding-top: 0px!important;">

                        <v-col cols="12" md="12"
                            style="font-weight:bold;margin-left:10px;color:white;font-size: 12px;margin-top:0px!important;padding-top: 10px!important;">

                            Data

                        </v-col>

                    </v-row>



                    <v-row style="background-color: black;">

                        <v-col cols="12" md="12" style="margin-top:10px!important">

                            <v-menu v-model="showPicker" :close-on-content-click="false" transitions="scale-transition"
                                offset-y max-width="290px" min-width="290px">
                                <template v-slot:activator="{ on }">
                                    <v-text-field id="fldInpData2" v-model="valData" label="" persistent-hint v-on="on"
                                        readonly color="#c39d63"></v-text-field>
                                </template>
                                <v-date-picker :first-day-of-week="1" day-name-format="dddd" v-model="valData"
                                    @input="showPicker = false" locale="it" color="#c39d63">
                                </v-date-picker>
                            </v-menu>

                        </v-col>

                    </v-row>


                    <v-row style="background-color: black;">

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-date" contain
                                    src="@/assets/btn_cancel3_smartbook_ins_book.png" max-width="30" max-height="30"
                                    style="cursor:pointer;color:black;margin-right:10px" transition="scale-transition"
                                    title="Annulla" @click="btnCancelDate" />

                                <v-img alt="" class="btn-confirm-date" contain
                                    src="@/assets/btn_confirm3_smartbook_ins_book.png" max-width="30" max-height="30"
                                    style="cursor:pointer" transition="scale-transition" title="Conferma"
                                    @click="btnConfirmDate" />

                            </div>

                        </v-col>

                    </v-row>


                    <v-row style="margin-bottom: 0px!important;">

                        <v-col cols="12" md="12" style="display: flex;margin-top: -24px!important;">


                            <div style="border-left: 5px solid #c39d63;
border-bottom: 5px solid #c39d63;
width: 30px;height: 30px;background-color: black;">&nbsp;</div>

                            <div style="width:240px;height:30px;background-color: black;">&nbsp;</div>

                            <div style="border-right: 5px solid #c39d63;
border-bottom: 5px solid #c39d63;
width: 30px;height: 30px;background-color: black;">&nbsp;</div>


                        </v-col>

                    </v-row>


                </modal>
            </template>

        </div>



        <div class="contpopuplocalechiuso" style="display:none">

            <template>
                <modal name="popupLocaleChiuso" :clickToClose="false" :width="350" :height="144">


                    <v-row style="margin-bottom: 0px!important;">

                        <v-col cols="12" md="12" style="display: flex;margin-bottom: 0px!important;">

                            <div style="border-left: 5px solid #c39d63;
border-top: 5px solid #c39d63;
width: 30px;height: 30px;background-color: black;">&nbsp;</div>

                            <div style="width:290px;height:30px;background-color: black;">&nbsp;</div>

                            <div style="border-right: 5px solid #c39d63;
border-top: 5px solid #c39d63;
width: 30px;height: 30px;background-color: black;">&nbsp;</div>


                        </v-col>

                    </v-row>



                    <v-row class="rowOrari" style="margin-top:-12px!important">

                        <v-col cols="12" md="12"
                            style="text-align: center;color:white;font-size: 12px;font-weight: bold;">

                            Oggi il locale è chiuso

                        </v-col>

                    </v-row>

                    <v-row class="rowOrari">

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-confirm-date" contain
                                    src="@/assets/btn_confirm3_smartbook_ins_book.png" max-width="30" max-height="30"
                                    style="cursor:pointer" transition="scale-transition" title="Conferma"
                                    @click="btnConfirmLocaChiuso" />

                            </div>

                        </v-col>

                    </v-row>

                    <v-row style="margin-bottom: 0px!important;">

                        <v-col cols="12" md="12" style="display: flex;margin-top: -24px!important;">


                            <div style="border-left: 5px solid #c39d63;
border-bottom: 5px solid #c39d63;
width: 30px;height: 30px;background-color: black;">&nbsp;</div>

                            <div style="width:290px;height:30px;background-color: black;">&nbsp;</div>

                            <div style="border-right: 5px solid #c39d63;
border-bottom: 5px solid #c39d63;
width: 30px;height: 30px;background-color: black;">&nbsp;</div>


                        </v-col>

                    </v-row>


                </modal>
            </template>

        </div>



        <div class="contpopuporari" style="display:none">

            <template>
                <modal name="popupOrari" :clickToClose="false" :width="300" :height="420">


                    <v-row style="margin-bottom: 0px!important;padding-bottom: 0px!important;">

                        <v-col cols="12" md="12" style="display: flex;margin-bottom: 0px!important;">

                            <div style="border-left: 3px solid #c39d63;
border-top: 3px solid #c39d63;
width: 30px;height: 30px;background-color: black;">&nbsp;</div>

                            <div style="width:240px;height:30px;background-color: black;">&nbsp;</div>

                            <div style="border-right: 3px solid #c39d63;
border-top: 3px solid #c39d63;
width: 30px;height: 30px;background-color: black;">&nbsp;</div>


                        </v-col>

                    </v-row>




                    <v-row class="rowOrari" style="margin-top:-12px!important;padding-top: 0px!important;">

                        <v-col cols="12" md="12"
                            style="margin-top:-11px!important;display: flex;justify-content: center;align-items: center;justify-items: center;">

                            <div>

                                <img src="@/assets/calendario-smartbook-ins-book.png" class="calendarButton"
                                    style="width:50px!important;cursor:pointer" title="Cambia Data" />

                            </div>

                        </v-col>

                    </v-row>

                    <v-row class="rowOrari" style="margin-top:6px!important;padding-top: 0px!important;">

                        <v-col cols="12" md="12"
                            style="display: flex;justify-content: center;align-items: center;justify-items: center;">

                            <div style="color:white;font-weight: bold;margin-left: 10px;margin-top: 3px;font-size:12px">
                                {{ valCurrDay }}
                            </div>

                            <div style="color:white;font-weight: bold;margin-left: 10px;margin-top: 3px;font-size:12px">
                                {{ descCurDay }}
                            </div>

                        </v-col>

                    </v-row>


                    <v-row class="rowOrari">

                        <v-col cols="12" md="12">

                            <v-list class="clListOrari">

                                <div class="headerOrariDisp">

                                    <div class="colRadioOrariDisp">&nbsp;
                                    </div>

                                    <div class="colHeaderCompositeOrariDisp">Ora</div>
                                    <div class="colHeaderTipoOrariDisp">Tipo</div>
                                    <div class="colHeaderMessaggioOrariDisp">&nbsp;</div>


                                </div>

                                <v-list-tile v-for="el in orariDisp" :key="el.id">

                                    <div class="rowOrariDisp">

                                        <div class="colRadioOrariDisp">

                                            <input class="val_select" type="radio" name="selPrenot"
                                                :excp-pranzo-cena="el.excp_pranzo_cena" :v-ora="el.v_ora"
                                                :data-id="el.id" />

                                        </div>

                                        <div class="colCompositeOrariDisp">{{ el.composite }}</div>
                                        <div class="colTipoOrariDisp">{{ el.tipo }}</div>
                                        <div class="colMessaggioOrariDisp">{{ el.messaggio }}</div>


                                    </div>




                                </v-list-tile>


                            </v-list>

                        </v-col>

                    </v-row>

                    <v-row style="background-color: black;">

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-confirm-date" contain
                                    src="@/assets/btn_confirm3_smartbook_ins_book.png" max-width="30" max-height="30"
                                    style="cursor:pointer" transition="scale-transition" title="Conferma"
                                    @click="btnConfirmOrari" />

                            </div>

                        </v-col>

                    </v-row>

                    <v-row style="margin-bottom: 0px!important;margin-top: 0px!important;">

                        <v-col cols="12" md="12" style="display: flex;">


                            <div style="border-left: 3px solid #c39d63;
border-bottom: 3px solid #c39d63;
width: 30px;height: 30px;background-color: black;">&nbsp;</div>

                            <div style="width:240px;height:30px;background-color: black;">&nbsp;</div>

                            <div style="border-right: 3px solid #c39d63;
border-bottom: 3px solid #c39d63;
width: 30px;height: 30px;background-color: black;">&nbsp;</div>


                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>


        <div class="contpopupselora" style="display:none">

            <template>
                <modal name="popupSelOra" :clickToClose="false" :width="350" :height="144">


                    <v-row style="margin-bottom: 0px!important;">

                        <v-col cols="12" md="12" style="display: flex;margin-bottom: 0px!important;">

                            <div style="border-left: 3px solid #c39d63;
border-top: 3px solid #c39d63;
width: 30px;height: 30px;background-color: black;">&nbsp;</div>

                            <div style="width:290px;height:30px;background-color: black;">&nbsp;</div>

                            <div style="border-right: 3px solid #c39d63;
border-top: 3px solid #c39d63;
width: 30px;height: 30px;background-color: black;">&nbsp;</div>


                        </v-col>

                    </v-row>



                    <v-row class="rowOrari" style="margin-top:-12px!important">

                        <v-col cols="12" md="12"
                            style="text-align: center;color:white;font-size: 12px;font-weight: bold;">

                            Selezionare un orario per prenotare

                        </v-col>

                    </v-row>

                    <v-row class="rowOrari">

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-confirm-date" contain
                                    src="@/assets/btn_confirm3_smartbook_ins_book.png" max-width="30" max-height="30"
                                    style="cursor:pointer" transition="scale-transition" title="Conferma"
                                    @click="btnConfirmSelOra" />

                            </div>

                        </v-col>

                    </v-row>

                    <v-row style="margin-bottom: 0px!important;">

                        <v-col cols="12" md="12" style="display: flex;margin-top: -24px!important;">


                            <div style="border-left: 3px solid #c39d63;
border-bottom: 3px solid #c39d63;
width: 30px;height: 30px;background-color: black;">&nbsp;</div>

                            <div style="width:290px;height:30px;background-color: black;">&nbsp;</div>

                            <div style="border-right: 3px solid #c39d63;
border-bottom: 3px solid #c39d63;
width: 30px;height: 30px;background-color: black;">&nbsp;</div>


                        </v-col>

                    </v-row>


                </modal>
            </template>

        </div>




        <div class="contpopupprenot" style="display:none">

            <template>
                <modal name="popupPrenot" :clickToClose="false" :width="350" :height="574">


                    <v-row style="margin-bottom: 0px!important;">

                        <v-col cols="12" md="12" style="display: flex;margin-bottom: 0px!important;">

                            <div style="border-left: 3px solid #c39d63;
border-top: 3px solid #c39d63;
width: 30px;height: 30px;background-color: black;">&nbsp;</div>

                            <div style="width:290px;height:30px;background-color: black;">&nbsp;</div>

                            <div style="border-right: 3px solid #c39d63;
border-top: 3px solid #c39d63;
width: 30px;height: 30px;background-color: black;">&nbsp;</div>


                        </v-col>

                    </v-row>



                    <v-row class="rowOrari" style="margin-top:-12px!important">

                        <v-col cols="12" md="12" style="display:flex">

                            <div style="color:white;margin-left:26px;font-size:12px">
                                Prenotazione:
                            </div>

                            <div id="data"
                                style="color:white;margin-left:10px;font-size:12px;text-align: right;width:191px"></div>



                        </v-col>

                    </v-row>

                    <v-row class="rowOrari" style="margin-top:-12px!important">

                        <v-col cols="12" md="12" style="display:flex">

                            <div style="color:white;margin-left:26px;font-size:12px">
                                Giorno / Ora:
                            </div>

                            <div style="display:flex;width:210px;justify-content: flex-end;">
                                <div id="giorno" style="color:white;margin-left:10px;font-size:12px"></div>
                                <div style="color:white;font-size:12px;margin-left: 5px;margin-right: 5px;">/</div>
                                <div id="ora" style="color:white;font-size:12px"></div>

                            </div>


                        </v-col>

                    </v-row>




                    <div class="contanagrafica" style="background-color: black;">

                        <div class="row">

                            <div class="col-md-12">

                                <div class="form-group" style="padding-left: 18px;padding-right: 18px;">

                                    <!-- <label style="color:white;margin-left: 10px;"><b>Data</b></label>
                                    <div class="form-rows" style="margin-bottom: 3px!important;">
                                        <div class="col-md-12" style="display: flex;margin-top:-16px!important">
                                            <input autocomplete="off" readonly placeholder="Data prenotazione"
                                                type="text" id="data" name="data" class="form-control" />

                                        </div>
                                        <div id="suggesstion-box"></div>

                                    </div> -->

                                    <!-- <label style="color:white;margin-left: 10px;"><b>Giorno</b></label>
                                    <div class="form-rows" style="margin-bottom: 3px!important;">
                                        <div class="col-md-12" style="display: flex;margin-top:-16px!important">
                                            <input autocomplete="off" readonly placeholder="Giorno prenotazione"
                                                type="text" id="giorno" name="giorno" class="form-control" />

                                        </div>
                                        <div id="suggesstion-box"></div>

                                    </div>

                                    <label style="color:white;margin-left: 10px;"><b>Ora</b></label>
                                    <div class="form-rows" style="margin-bottom: 3px!important;">
                                        <div class="col-md-12" style="display: flex;margin-top:-16px!important">
                                            <input autocomplete="off" readonly placeholder="Ora prenotazione"
                                                type="text" id="ora" name="ora" class="form-control" />

                                        </div>
                                        <div id="suggesstion-box"></div>

                                    </div> -->

                                    <label style="color:white;margin-left: 10px;"><b>Nome e cognome</b></label>
                                    <div class="form-rows" style="margin-bottom: 3px!important;">
                                        <div class="col-md-12" style="display: flex;margin-top:-16px!important">
                                            <input autocomplete="off" type="text" id="name" name="name"
                                                class="form-control" :value="nomeCognomeParente" />

                                        </div>
                                        <div id="suggesstion-box" class="errorMessageNome"></div>

                                    </div>

                                    <label style="color:white;margin-left: 10px;"><b>Telefono</b></label>
                                    <div class="form-rows" style="margin-bottom: 3px!important;">
                                        <div class="col-md-12" style="display: flex;margin-top:-16px!important">
                                            <input step="1" min="0" max="12131231231231123" type="number" :value="phone"
                                                id="phone" name="phone" class="form-control" />

                                        </div>
                                        <div id="suggesstion-box" class="errorMessagePhone"></div>

                                    </div>

                                    <label style="color:white;margin-left: 10px;"><b>Mail</b></label>
                                    <div class="form-rows" style="margin-bottom: 3px!important;">
                                        <div class="col-md-12" style="display: flex;margin-top:-16px!important">
                                            <input type="email" id="email" name="email" class="form-control"
                                                :value="email" />

                                        </div>
                                        <div id="suggesstion-box" class="errorMessageMail"></div>

                                    </div>

                                    <label style="color:white;margin-left: 10px;"><b>Numero persone</b></label>
                                    <div class="form-rows" style="margin-bottom: 3px!important;">
                                        <div class="col-md-12" style="display: flex;margin-top:-16px!important">
                                            <input min="1" max="100" type="number" id="num_persons" name="num_persons"
                                                class="form-control" />

                                        </div>
                                        <div id="suggesstion-box" class="errorMessageNumPersone"></div>

                                    </div>

                                    <label style="color:white;margin-left: 10px;"><b>Note</b></label>
                                    <div class="form-rows" style="margin-bottom: 3px!important;">
                                        <div class="col-md-12" style="display: flex;margin-top:-16px!important">
                                            <textarea style="min-height: 54px;" id="note" name="note"
                                                class="form-control"></textarea>

                                        </div>
                                        <div id="suggesstion-box"></div>

                                    </div>



                                </div>



                            </div>



                        </div>


                    </div>


                    <v-row class="rowOrari">

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-confirm-prenot" contain
                                    src="@/assets/btn_confirm3_smartbook_ins_book.png" max-width="30" max-height="30"
                                    style="cursor:pointer" transition="scale-transition" title="Conferma"
                                    @click="btnConfirmPrenot" />

                                <v-img alt="" class="btn-back-prenot" contain
                                    src="@/assets/icon-back-smartbook-ins-book.png" max-width="30" max-height="30"
                                    style="cursor:pointer;margin-left: 22px;" transition="scale-transition"
                                    title="Indietro" @click="btnBackPrenot" />

                            </div>

                        </v-col>

                    </v-row>

                    <v-row style="margin-bottom: 0px!important;margin-top: 0px!important;">

                        <v-col cols="12" md="12" style="display: flex">


                            <div style="border-left: 3px solid #c39d63;
border-bottom: 3px solid #c39d63;
width: 30px;height: 30px;background-color: black;">&nbsp;</div>

                            <div style="width:290px;height:30px;background-color: black;">&nbsp;</div>

                            <div style="border-right: 3px solid #c39d63;
border-bottom: 3px solid #c39d63;
width: 30px;height: 30px;background-color: black;">&nbsp;</div>


                        </v-col>

                    </v-row>


                </modal>
            </template>

        </div>

        <div class="contpopupmsg" style="display:none">

            <template>
                <modal name="popupMsg" :clickToClose="false" :width="350" :height="144">


                    <v-row style="margin-bottom: 0px!important;">

                        <v-col cols="12" md="12" style="display: flex;margin-bottom: 0px!important;">

                            <div style="border-left: 3px solid #c39d63;
border-top: 3px solid #c39d63;
width: 30px;height: 30px;background-color: black;">&nbsp;</div>

                            <div style="width:290px;height:30px;background-color: black;">&nbsp;</div>

                            <div style="border-right: 3px solid #c39d63;
border-top: 3px solid #c39d63;
width: 30px;height: 30px;background-color: black;">&nbsp;</div>


                        </v-col>

                    </v-row>



                    <v-row class="rowOrari" style="margin-top:-12px!important">

                        <v-col cols="12" md="12"
                            style="text-align: center;color:white;font-size: 12px;font-weight: bold;">

                            {{ textMessage }}

                        </v-col>

                    </v-row>

                    <v-row class="rowOrari">

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-confirm-msg" contain
                                    src="@/assets/btn_confirm3_smartbook_ins_book.png" max-width="30" max-height="30"
                                    style="cursor:pointer" transition="scale-transition" title="Conferma"
                                    @click="btnConfirmMsg" />

                            </div>

                        </v-col>

                    </v-row>

                    <v-row style="margin-bottom: 0px!important;">

                        <v-col cols="12" md="12" style="display: flex;margin-top: -24px!important;">


                            <div style="border-left: 3px solid #c39d63;
border-bottom: 3px solid #c39d63;
width: 30px;height: 30px;background-color: black;">&nbsp;</div>

                            <div style="width:290px;height:30px;background-color: black;">&nbsp;</div>

                            <div style="border-right: 3px solid #c39d63;
border-bottom: 3px solid #c39d63;
width: 30px;height: 30px;background-color: black;">&nbsp;</div>


                        </v-col>

                    </v-row>


                </modal>
            </template>

        </div>

        <div class="contpopupconf" style="display:none">

            <template>
                <modal name="popupConf" :clickToClose="false" :width="462" :height="96">


                    <v-row style="margin-bottom: 0px!important;">

                        <v-col cols="12" md="12" style="display: flex;margin-bottom: 0px!important;">

                            <div style="border-left: 3px solid #c39d63;
border-top: 3px solid #c39d63;
width: 30px;height: 30px;background-color: black;">&nbsp;</div>

                            <div style="width:402px;height:30px;background-color: black;">&nbsp;</div>

                            <div style="border-right: 3px solid #c39d63;
border-top: 3px solid #c39d63;
width: 30px;height: 30px;background-color: black;">&nbsp;</div>


                        </v-col>

                    </v-row>



                    <v-row class="rowOrari" style="margin-top:-12px!important">

                        <v-col cols="12" md="12"
                            style="text-align: center;color:white;font-size: 16px;font-weight: bold;">

                            La prenotazione è stata inviata correttamente

                        </v-col>

                    </v-row>

                    <v-row style="margin-bottom: 0px!important;">

                        <v-col cols="12" md="12" style="display: flex;margin-top: -24px!important;">


                            <div style="border-left: 3px solid #c39d63;
border-bottom: 3px solid #c39d63;
width: 30px;height: 30px;background-color: black;">&nbsp;</div>

                            <div style="width:402px;height:30px;background-color: black;">&nbsp;</div>

                            <div style="border-right: 3px solid #c39d63;
border-bottom: 3px solid #c39d63;
width: 30px;height: 30px;background-color: black;">&nbsp;</div>


                        </v-col>

                    </v-row>


                </modal>
            </template>

        </div>


        <div class="contpopupconfmobile" style="display:none">

            <template>
                <modal name="popupConfMobile" :clickToClose="false" :width="321" :height="120">


                    <v-row style="margin-bottom: 0px!important;">

                        <v-col cols="12" md="12" style="display: flex;margin-bottom: 0px!important;">

                            <div style="border-left: 3px solid #c39d63;
border-top: 3px solid #c39d63;
width: 30px;height: 30px;background-color: black;">&nbsp;</div>

                            <div style="width:402px;height:30px;background-color: black;">&nbsp;</div>

                            <div style="border-right: 3px solid #c39d63;
border-top: 3px solid #c39d63;
width: 30px;height: 30px;background-color: black;">&nbsp;</div>


                        </v-col>

                    </v-row>



                    <v-row class="rowOrari" style="margin-top:-12px!important">

                        <v-col cols="12" md="12"
                            style="text-align: center;color:white;font-size: 16px;font-weight: bold;">

                            La prenotazione è stata inviata correttamente

                        </v-col>

                    </v-row>

                    <v-row style="margin-bottom: 0px!important;">

                        <v-col cols="12" md="12" style="display: flex;margin-top: -24px!important;">


                            <div style="border-left: 3px solid #c39d63;
border-bottom: 3px solid #c39d63;
width: 30px;height: 30px;background-color: black;">&nbsp;</div>

                            <div style="width:402px;height:30px;background-color: black;">&nbsp;</div>

                            <div style="border-right: 3px solid #c39d63;
border-bottom: 3px solid #c39d63;
width: 30px;height: 30px;background-color: black;">&nbsp;</div>


                        </v-col>

                    </v-row>


                </modal>
            </template>

        </div>


    </div>
</template>

<script>
import apibookingrsa from "../utils/bookingrsa/apibookingrsa";

import {
    bus
} from "../main";
import router from ".././router";
//import $ from 'jquery';

export default {
    destroyed() {
        this.$root.$children[0].bnavbuttons = [];

        bus.$off("bottomIconClickEvent");
    },

    mounted: function () {

        /*eslint-disable no-undef*/

        this.$root.$children[0].viewUserInfo = false;



        $(".wrap_buttons_footer").addClass("wrap_buttons_footer2");


        setTimeout(() => {

            $("#btn_left").addClass("clButtonLeft");

            $("#btn_middle2").addClass("clButtonMiddle2");

        }, 500);






        $("#btn_right").css("visibility", "hidden");

        window.uname = window.$cookies.get("username");

        window.divSection = 0; // 0 griglia, 1 daati input prenotazione


        window.hourSelect = "";

        window.idPeriodSel = "";

        //alert ( window.uname ) ;

        var that = this;

        var settSmartbook = JSON.parse(window.$cookies.get("settSmartbook"));
        console.log("settSmartbook", settSmartbook[0]);

        var is_admin_company = window.$cookies.get("b");

        if (is_admin_company == 'Y' || is_admin_company == 'S')
            this.$is_admin_company = 1;
        else
            this.$is_admin_company = 0;


        $(document).ready(

            function () {
                $("body").off();

                // var uname = "fagostoni";

                window.uname = window.$cookies.get("username");
                var uname = window.uname;




                const args = [uname, true, ['bar', 5], {
                    foo: 'baz'
                }];
                try {
                    console.log("entering user");
                    window.flutter_inappwebview.callHandler('setLoggedUser', ...args);
                    console.log("passed");

                } catch (error) {
                    // alert(error);
                }

            }
        );

        $(document).ready(function () {

            $('body').on('click', '.calendarButton', function () {

                that.$modal.hide('popupOrari');



                setTimeout(() => {

                    $(".contpopupdate").show();

                    that.$modal.show('popupDate');

                }, 200);





            });



            $('body').on('click', '#footer-link', function (event) {
                event.preventDefault();

                const args = [1, true, ['bar', 5], {
                    foo: 'baz'
                }];
                window.flutter_inappwebview.callHandler('openLcHandler', ...args);

                return false;
            });

            $('body').on('click', '#privacy-link', function (event) {
                event.preventDefault();

                const args = [1, true, ['bar', 5], {
                    foo: 'baz'
                }];
                window.flutter_inappwebview.callHandler('openPrivacyHandler', ...args);

                return false;
            });

            $('body').on('click', '#hdtool-link', function (event) {
                event.preventDefault();

                const args = [1, true, ['bar', 5], {
                    foo: 'baz'
                }];
                window.flutter_inappwebview.callHandler('openHdHandler', ...args);

                return false;
            });

        });


        if (bus !== undefined)
            bus.$on("bottomIconClickEvent", (data) => {
                //this.header = data;
                console.log(data);
                switch (data) {

                    case "btn_exit":
                        router.push({
                            path: "/dash"
                        });
                        break;

                    case "btn_info":
                        router.push({
                            path: "/info"
                        });
                        break;

                    case "btn_pdf":

                        this.createPdf();

                        break;

                    default:
                        break;
                }
            });

        this.setupButtons();



        this.getHoursAvailable();



        $(".spin").hide();



        $(".bkc_over").css("opacity", "0");


        if (window.innerWidth <= 768) {


            $(".v-toolbar__content").css("background-image", "linear-gradient(rgba(0,0,0,0.8), rgba(0,0,0,0.8)), url(https://trattoriadapasqualino.it/wp-content/uploads/2019/04/restaurant-hero-01.jpg)");
            $(".v-toolbar__content").css("background-size", "cover");
            $(".v-toolbar__content").css("height", "100vh");
            $(".v-toolbar__content").css("align-items", "start");
            $(".curDipTop").css("margin-top", "18px");

        }
        else {


            $(".v-toolbar__content").css("background-image", "linear-gradient(rgba(0,0,0,0.8), rgba(0,0,0,0.8)), url(https://trattoriadapasqualino.it/wp-content/uploads/2019/04/restaurant-hero-01.jpg)");
            $(".v-toolbar__content").css("background-size", "cover");
            $(".v-toolbar__content").css("height", "100vh");
            $(".v-toolbar__content").css("align-items", "start");
            $(".curDipTop").css("margin-top", "18px");

        }


        $(".clTitle").remove();

        // $(".v-toolbar__content").append("<div class='clTitleContainer'><div><img src='https://app.smart-book.it/public/documents/smartbook/tmp/000168.jpeg' /></div><div id='testoTitolo'>Prenotazione</div></div>");


        $(".v-toolbar__content").append("<div class='clTitleContainer'><div style='table-row'><div class='clColLogo'><img src='https://app.smart-book.it/public/documents/smartbook/tmp/000168.jpeg' /></div></div><div style='table-row'><div class='clColTitle'><div id='testoTitolo'>Prenotazione</div></div></div></div>");


        $(".bottomBtnMenu").remove();

        $("#bottomNavigation").hide();

        // $("#no-background-hover").removeAttr('href');

        this.$root.$children[0].disableHome = true;



        setTimeout(() => {

            $('#logoApp').attr('style', 'display: none !important;');


        }, 200);


    },
    data() {
        var dt = new Date();
        dt.setDate(dt.getDate() + 3);

        dt = dt.toISOString().slice(0, 10).replace('T', ' ');

        var $status = [{
            'Giorno': 'Giorno'
        }, {
            'Pomer./Sera': 'Pomer./Sera'
        }];

        return {

            textMessage: "",

            dataPrenotazione: "",

            valCurrDay: "",

            descCurDay: "",

            orariDisp: [],

            giorniSettimana: ['Domenica', 'Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato'],

            valData: null,

            is_admin_company: 0,
            status: $status,
            futureTime: dt,
            selSector: null,
            selSector2: null,
            settoridb: [],

            phone: "",
            tipi: [],
            is_2_ospite: "",
            ospite: {
                nome: "",
                codicefiscale: "",
                cognome: "",
            },
            ospite2: {
                nome: "",
                codicefiscale: "",
                cognome: "",
            },
            nomeCognomeParente: "",
            showPicker: false,
            profile: {},
            valid: true,
            initials: "",
            namesurname: "",
            iban: "",
            cellulare: "",
            codicefiscale: "",
            codicefiscaleRules: [
                (v) => !!v || "Codice fiscale is required",
                (v) =>
                    (v && v.length <= 16) ||
                    "Codice fiscale must be less than 16 characters",
            ],
            cellulareRules: [
                (v) => !!v || "Phone number is required",
                (v) =>
                    (v && v.length <= 30) || "Phone number must be less than 15 characters",
            ],
            genRules: [(v) => !!v || "Field required"],
            ibanRules: [
                (v) => !!v || "Iban is required",
                (v) => (v && v.length <= 30) || "Iban must be less than 30 characters",
            ],
            email: "",
            emailRules: [
                (v) => !!v || "E-mail is required",
                (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
            ],
            select: null,
            items: ["Item 1", "Item 2", "Item 3", "Item 4"],
            checkbox: false,
        }
    },

    methods: {

        btnBackPrenot: function () {

            var that = this;

            that.$modal.hide('popupPrenot');

            setTimeout(() => {

                $("#testoTitolo").show();


                $(".contpopuporari").show();

                that.$modal.show('popupOrari');


            }, 200);

        },

        showMessageConf: function () {

            var that = this;


            if (window.innerWidth <= 768) {

                $(".contpopupconfmobile").show();

                that.$modal.show('popupConfMobile');

                // setTimeout(() => {

                //     that.$modal.hide('popupConfMobile');


                // }, 3000);
            }
            else {

                $(".contpopupconf").show();

                that.$modal.show('popupConf');

                setTimeout(() => {

                    that.$modal.hide('popupConf');


                }, 3000);
            }







        },



        sendBooking: function () {

            var vpointer = this;

            var username = window.$cookies.get("username");

            var is_admin = 0;
            if (window.is_admin == 'Y' || window.is_admin == 'S') is_admin = 1;



            var $is_admin_company = is_admin;

            console.log($is_admin_company);


            var maxbookingperweek = window.settSmartbook.maxbookingperweek;

            var settorepiano = $("#settorepiano").val();
            var namepaziente = $("#namepaziente").val();

            var settorepiano_2 = "";

            if ($("#settorepiano_2").length > 0) {
                settorepiano_2 = $("#settorepiano_2").val();
            }

            console.log(settorepiano_2);

            var namepaziente_2 = "";

            if ($("#namepaziente_2").length > 0) {
                namepaziente_2 = $("#namepaziente_2").val();
            }

            console.log(namepaziente_2);

            var sb_periods_id = window.idPeriodSel;
            var dataora = window.currDay + " " + window.hourSelect;

            var nameprenot = window.nameprenot;
            var phoneprenot = window.phoneprenot;
            var emailprenot = window.emailprenot;
            var numpersprenot = window.numpersprenot;
            var noteprenot = window.noteprenot;

            var b_type = $("#bookingtype").val();

            $(".spin").show();

            var dataToPass = {
                "maxbookingperweek": maxbookingperweek,
                "settorepiano": settorepiano,
                "namepaziente": namepaziente,
                "sb_periods_id": sb_periods_id,
                "name": nameprenot,
                "note": noteprenot,
                "period": $("#period").val(),
                "surname": $("#surname").val(),
                "phone": phoneprenot,
                "email": emailprenot,
                "num_persons": numpersprenot,
                "booking_date_time": dataora,
                "booking_type": b_type,
                "cod_fis_ospite": $("#cod_fis_ospite").val(),
                "cid": window.cid,
                "is_admin": is_admin,
                "username": username,
                "asker": "bookinggen"

            };
            console.log("DataToPass", dataToPass);

            var endPointCheck = window.basepath + "checkMaxPerWeekApi";

            //Check if can do
            $.ajax({

                url: endPointCheck,
                data: dataToPass,
                error: function (data) {
                    var er = data.responseJSON.msg;

                    vpointer.$swal({
                        icon: 'error',
                        text: er + " !",
                        showConfirmButton: false,
                        timer: 3500
                    }

                    );
                    $(".spin").hide();

                }

                ,
                success: function (data) {
                    console.log(data);

                    var endPointBook = window.basepath + "createPdfSendMailApi";
                    var passData = {

                        "maxbookingperweek": maxbookingperweek,
                        "settorepiano": settorepiano,
                        "namepaziente": namepaziente,
                        "sb_periods_id": sb_periods_id,
                        "name": nameprenot,
                        "note": noteprenot,
                        "period": $("#period").val(),
                        "surname": $("#surname").val(),
                        "phone": phoneprenot,
                        "email": emailprenot,
                        "num_persons": numpersprenot,
                        "booking_date_time": dataora,
                        "booking_type": b_type,
                        "cod_fis_ospite": $("#cod_fis_ospite").val(),

                        "settorepiano_2": settorepiano_2,
                        "namepaziente_2": namepaziente_2,
                        "cod_fis_ospite_2": $("#cod_fis_ospite_2").val(),
                        "cid": window.cid,
                        "is_admin": is_admin,
                        "username": username,

                    };

                    $.ajax({

                        url: endPointBook,
                        data: passData,
                        error: function (data) {
                            var er = data.responseJSON.msg;

                            //  console.log ( data ) ;
                            vpointer.$swal({
                                icon: 'error',
                                text: er + " !",
                                showConfirmButton: false,
                                timer: 3500
                            }

                            );
                            $(".spin").hide();

                        }

                        ,
                        success: function (data) {

                            // $(".spin").hide();
                            var res = data.Result;
                            // var res = "OK" ;
                            var error_msg = data.Errore;
                            var v_url = data.ValUrl;

                            console.log(error_msg, v_url);

                            if (res == "OK") {


                                var endPointNotify = window.basepath + "sendNotifyApi";
                                // call method send notify
                                $.ajax({

                                    url: endPointNotify,
                                    data: {

                                        "maxbookingperweek": maxbookingperweek,
                                        "settorepiano": settorepiano,
                                        "namepaziente": namepaziente,
                                        "sb_periods_id": sb_periods_id,
                                        "name": $("#name").val(),
                                        "note": $("#note").val(),
                                        "period": $("#period").val(),
                                        "surname": $("#surname").val(),
                                        "phone": $("#phone").val(),
                                        "email": $("#email").val(),
                                        "num_persons": $("#num_persons").val(),
                                        "booking_date_time": dataora,
                                        "booking_type": b_type,
                                        "cod_fis_ospite": $("#cod_fis_ospite").val(),
                                        "settorepiano_2": settorepiano_2,
                                        "namepaziente_2": namepaziente_2,
                                        "cod_fis_ospite_2": $("#cod_fis_ospite_2").val(),
                                        "azienda_id": window.settSmartbook.cid,
                                        "cid": window.cid,
                                        "is_admin": is_admin,
                                        "username": username,
                                        "sector": window.sector,

                                    }

                                    ,
                                    error: function (data) {
                                        var er = data.responseJSON.msg;
                                        //  console.log ( data ) ;
                                        vpointer.$swal({
                                            icon: 'error',
                                            text: er + " !",
                                            showConfirmButton: false,
                                            timer: 3500
                                        }

                                        );
                                        $(".spin").hide();
                                    },
                                    success: function (data) {

                                        console.log(data);

                                    }

                                }

                                );




                                //before sending notify we give a msg to the user
                                $(".spin").hide();
                                // var res = "OK";
                                if (res == "OK") {



                                    vpointer.showMessageConf();

                                    //what to do after
                                    if ($is_admin_company != 1) {
                                        var cid = window.cid;
                                        if (cid == 168) {
                                            var dest = "https://trattoriadapasqualino.it"
                                            setTimeout(
                                                function () {

                                                    console.log(dest);

                                                    window.location.href = dest;
                                                }, 3000);
                                        } else {
                                            resetForAdmin();
                                        }

                                    }

                                    if ($is_admin_company == 1) {
                                        resetForAdmin();
                                    }

                                }
                                //end process

                                console.log("SEND_NOTIFY_API");



                            } else {
                                $(".spin").hide();
                                //   console.log (  res ) ;
                                res = data.msg;

                                vpointer.$swal({
                                    icon: 'error',
                                    text: res + " !",
                                    showConfirmButton: false,
                                    timer: 3500
                                }

                                );

                                return false;
                            }


                        }
                    }

                    );

                    //END




                }
            }

            );


        },

        isEmail: function (email) {
            //return email;
            if (email.length == 0) return true;
            //eslint-disable-next-line
            var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
            return regex.test(email);
        },

        showMessage: function () {

            $(".contpopupmsg").show();

            this.$modal.show('popupMsg');

        },

        btnConfirmMsg: function () {

            this.$modal.hide('popupMsg');

        },

        btnConfirmPrenot: function () {

            var that = this;

            var errore = 0;

            $(".errorMessageNome").html("&nbsp;");
            $(".errorMessagePhone").html("&nbsp;");
            $(".errorMessageMail").html("&nbsp;");
            $(".errorMessageNumPersone").html("&nbsp;");

            if ($("#name").val() == "" || $("#name").val() == " ") {
                errore = 1;
                $(".errorMessageNome").html("Campo richiesto");
            }

            if (errore == 0) {
                if ($("#phone").val() == "" || $("#phone").val() == " ") {
                    errore = 1;
                    $(".errorMessagePhone").html("Campo richiesto");
                }
            }

            if (errore == 0) {
                if ($("#email").val() == "" || $("#email").val() == " ") {
                    errore = 1;
                    $(".errorMessageMail").html("Campo richiesto");
                }
            }

            if (errore == 0) {
                if (!that.isEmail($("#email").val())) {
                    errore = 1;
                    $(".errorMessageMail").html("Valore non valida");
                }
            }

            if (errore == 0) {
                if ($("#num_persons").val() == "") {
                    errore = 1;
                    $(".errorMessageNumPersone").html("Campo richiesto");
                }
            }

            // console.log("ERRORE: ", errore);





            if (errore == 0) {

                window.nameprenot = $("#name").val();
                window.phoneprenot = $("#phone").val();
                window.emailprenot = $("#email").val();
                window.numpersprenot = $("#num_persons").val();
                window.noteprenot = $("#note").val();


                console.log("ok");

                that.$modal.hide('popupPrenot');

                setTimeout(() => {

                    that.sendBooking();

                }, 200);



            }




        },

        showSelOre: function () {

            $(".contpopupselora").show();

            this.$modal.show('popupSelOra');



        },

        btnConfirmSelOra: function () {

            this.$modal.hide('popupSelOra');


            setTimeout(() => {

                $(".contpopuporari").show();

                that.$modal.show('popupOrari');

            }, 200);

        },


        btnConfirmOrari: function () {

            var tot = 0;
            $('.val_select').each(function () {
                if ($(this).is(':checked')) {
                    tot = tot + 1;

                    window.hourSelect = $(this).attr("v-ora");

                    window.idPeriodSel = $(this).attr("data-id");

                }
            });


            console.log("TOT: ", tot);
            console.log("HOURSEL: ", window.hourSelect);
            console.log("IDPERIODSEL: ", window.idPeriodSel);


            if (tot > 0) {

                this.$modal.hide('popupOrari');


                this.checkBookingHour(67, "09-10-2023");



            }
            else {

                this.showSelOre();


            }


            //this.$modal.hide('popupOrari');


        },


        btnConfirmLocaChiuso: function () {

            var that = this;

            this.$modal.hide('popupLocaleChiuso');

            setTimeout(() => {

                that.showOrari();

            }, 200);


        },


        showLocChiuso: function () {

            $(".contpopuplocalechiuso").show();

            this.$modal.show('popupLocaleChiuso');



        },


        showOrari: function () {

            var that = this;


            that.valCurrDay = window.currDay;

            that.descCurDay = this.getDescDay()

            setTimeout(() => {

                $(".contpopuporari").show();

                that.$modal.show('popupOrari');

            }, 200);





        },

        getHoursAvailableWithDate: async function (v_date) {

            var that = this;


            // var pointerVue = this;

            // var dateTest = new Date();
            // dateTest = dateTest.setDate(dateTest.getDate() + 365);
            // const dateTest2 = new Date(dateTest).toJSON().slice(0, 10);


            // var $futureTime = dateTest2;
            // var username = window.$cookies.get("username");

            // var is_su = window.$cookies.get("a");
            // window.is_su = is_su;

            // var is_admin = window.$cookies.get("b");
            // window.is_admin = is_admin;

            // var settSmartbook = JSON.parse(window.$cookies.get("settSmartbook"));

            // var namesurname = window.$cookies.get("namesurname");

            // console.log("settSmartbook", settSmartbook[0]);

            // console.log(pointerVue, date, $futureTime, username, namesurname);

            // window.cid = settSmartbook[0].azienda_id;
            // window.settSmartbook = settSmartbook[0];

            // var $mindaystobook = "";
            // var date = new Date();
            // var $curday = null;
            // console.log($curday);
            // if ($mindaystobook) {

            //     //$curday  = date('Y-m-d' , strtotime("+$mindaystobook days") );
            //     $curday = date.toISOString().slice(0, 10).replace('T', ' ');
            // } else {
            //     //$curday = date('Y-m-d') ;
            //     $curday = date.toISOString().slice(0, 10).replace('T', ' ');
            // }

            //init filters
            window.currDay = v_date;
            window.lastDateSel = v_date;


            // console.log("LAST DATE: ", window.lastDateSel);

            // if (window.lastDateSel != undefined) {

            //     window.currDay = window.lastDateSel;
            //     window.currDayTo = window.lastDateSel;


            // }

            console.log("USERNAME 2: ", window.uname);
            console.log("CID 2: ", window.cid);
            console.log("IS_SU 2: ", window.is_su);
            console.log("BOOKINGTYPE 2: ", $("#bookingtype").val());
            console.log("CURRDAY 2: ", window.currDay);
            console.log("CURRDAYTO 2: ", window.currDayTo);
            console.log("CURSTATUS 2: ", window.curStatus);




            $(".spin").show();

            var response = await apibookingrsa.getHoursAvailable(
                window.uname,
                window.cid,
                window.is_su,
                $("#bookingtype").val(),
                window.currDay,
                window.currDayTo,
                window.curStatus
            ).then((res) => {

                $(".spin").hide();
                console.log("res from getHoursAvailable", res);

                that.orariDisp = res.data.Days;

                setTimeout(() => {

                    that.showOrari();

                }, 200);




            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );


        },


        getHoursAvailable: async function () {

            var that = this;

            window.basepath = that.getBasePath();

            var pointerVue = this;

            var dateTest = new Date();
            dateTest = dateTest.setDate(dateTest.getDate() + 365);
            const dateTest2 = new Date(dateTest).toJSON().slice(0, 10);


            var $futureTime = dateTest2;
            var username = window.$cookies.get("username");

            var is_su = window.$cookies.get("a");
            window.is_su = is_su;

            var is_admin = window.$cookies.get("b");
            window.is_admin = is_admin;

            var settSmartbook = JSON.parse(window.$cookies.get("settSmartbook"));

            var namesurname = window.$cookies.get("namesurname");

            console.log("settSmartbook", settSmartbook[0]);

            console.log(pointerVue, date, $futureTime, username, namesurname);

            window.cid = settSmartbook[0].azienda_id;
            window.settSmartbook = settSmartbook[0];

            var $mindaystobook = "";
            var date = new Date();
            var $curday = null;
            console.log($curday);
            if ($mindaystobook) {

                //$curday  = date('Y-m-d' , strtotime("+$mindaystobook days") );
                $curday = date.toISOString().slice(0, 10).replace('T', ' ');
            } else {
                //$curday = date('Y-m-d') ;
                $curday = date.toISOString().slice(0, 10).replace('T', ' ');
            }

            //init filters
            window.currDay = $curday;
            //window.currDay = "2025-02-05";

            window.currDayTo = $curday;
            //window.currDayTo = "2025-01-05";

            console.log("LAST DATE: ", window.lastDateSel);

            if (window.lastDateSel != undefined) {

                window.currDay = window.lastDateSel;
                window.currDayTo = window.lastDateSel;


            }

            window.curStatus = "0";

            that.valData = $curday;

            console.log("USERNAME: ", window.uname);
            console.log("CID: ", window.cid);
            console.log("IS_SU: ", window.is_su);
            console.log("BOOKINGTYPE: ", $("#bookingtype").val());
            console.log("CURRDAY: ", window.currDay);
            console.log("CURRDAYTO: ", window.currDayTo);
            console.log("CURSTATUS: ", window.curStatus);




            $(".spin").show();

            var response = await apibookingrsa.getHoursAvailable(
                window.uname,
                window.cid,
                window.is_su,
                $("#bookingtype").val(),
                window.currDay,
                window.currDayTo,
                window.curStatus
            ).then((res) => {

                $(".spin").hide();
                console.log("res from getHoursAvailable", res);

                that.orariDisp = res.data.Days;

                if (res.data.localeChiuso == 0) {

                    setTimeout(() => {

                        that.showOrari();

                    }, 200);

                }

                if (res.data.localeChiuso == 1) {

                    setTimeout(() => {

                        that.showLocChiuso();

                    }, 200);

                }



            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );


        },


        getDescDay() {

            var v_desc_day = "";

            var v_date = new Date(window.currDay);

            var indiceGiorno = v_date.getDay();

            v_desc_day = this.giorniSettimana[indiceGiorno];

            return v_desc_day;

        },



        handleInput(e) {
            const date = e.target.value;
            if (new Date(date).isValid()) {
                this.valData = date;
            }
        },

        checkBookingHour: async function (v_id, v_data) {

            var that = this;

            $(".spin").show();

            var response = await apibookingrsa.checkBookingHour(
                v_id,
                v_data
            ).then((res) => {

                $(".spin").hide();
                console.log("res from checkBookingHour", res);


                $(".contpopupprenot").show();

                that.$modal.show('popupPrenot');

                setTimeout(() => {

                    //$("#data").val(window.currDay);
                    $("#data").html(window.currDay);

                    //$("#giorno").val(that.getDescDay());
                    $("#giorno").html(that.getDescDay());

                    //$("#ora").val(window.hourSelect);
                    $("#ora").html(window.hourSelect);

                    $(".errorMessageNome").html("&nbsp;");

                    $(".errorMessagePhone").html("&nbsp;");

                    $(".errorMessageMail").html("&nbsp;");

                    $(".errorMessageNumPersone").html("&nbsp;");

                    $("#testoTitolo").hide();

                }, 200);


                // that.dataPrenotazione = window.currDay;


                // setTimeout(() => {

                //     $(".contpopupprenot").show();

                //     that.$modal.show('popupPrenot');

                // }, 200);


                // setTimeout(
                //     function () {

                //         // var dataora = window.currDay + " " + window.hourSelect;
                //         // $("#data").val(dataora);

                //         $("#data").val(window.currDay);

                //         $("#ora").val(window.hourSelect);

                //         $("#giorno").val(that.getDescDay());

                //     }
                //     , 600);




                // setTimeout(function () {


                //     $("#btn_middle2").css("visibility", "hidden");

                //     $(".swiper-button-next").trigger("click");

                //     //    $(".two").hide();
                //     //    $(".swipeAnagrafica").show();


                // }, 200);






                // setTimeout(
                //     function () {

                //         var dataora = $("tr.active ion-datas").text() + " " + $("tr.active ion-items").text();
                //         $("#data").val(dataora);
                //     }
                //     , 600);




                // setTimeout(function () {
                //     $(".swiper-button-next").trigger("click");
                // }, 200);






            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );


        },


        checkBookingHourOld: async function (v_id, v_data) {

            var that = this;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apibookingrsa.checkBookingHour(
                v_id,
                v_data
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from checkBookingHour", res);


                if (res.data.Result == "OK") {

                    setTimeout(
                        function () {

                            var dataora = $("tr.active ion-datas").text() + " " + $("tr.active ion-items").text();
                            $("#data").val(dataora);
                        }
                        , 600);




                    setTimeout(function () {
                        $(".swiper-button-next").trigger("click");
                    }, 200);

                }
                else {


                    that.$swal

                        .fire({
                            title: "Attenzione",
                            html: res.data.ErrDetails,
                            icon: "warning",
                            showCancelButton: false,
                            showConfirmButton: true,
                            confirmButtonText: "<img title='Conferma' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_confirm3_smartbook.png' />",
                            cancelButtonText: "<img title='Annulla' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_cancel3_smartbook.png' />",
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                            customClass: {
                                cancelButton: 'order-1',
                                confirmButton: 'order-2',
                            }

                        })
                        .then(async function (result) {

                            if (result.value == true) {

                                console.log("ok");

                            }

                        });




                }




            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );


        },



        btnCancelDate: function () {

            var that = this;

            that.$modal.hide('popupDate');

            setTimeout(() => {

                $(".contpopuporari").show();

                that.$modal.show('popupOrari');

            }, 200);

        },

        btnResetDate: function () {

            this.valData = null;

        },

        btnConfirmDate: function () {


            console.log("DATA: ", this.valData);

            var error = "";
            if (this.valData == null) {
                error = "Specificare una data";
            }
            else {
                if (this.valData == "") {
                    error = "Specificare una data";
                }
            }


            if (error == "") {

                this.$modal.hide('popupDate');


                // $("#btn_middle2").css("visibility", "hidden");


                // window.currDay = this.valData;

                // window.lastDateSel = this.valData;

                // $("#valDataSel").html(window.currDay);

                // $("#valDescDataSel").html(this.getDescDay());

                // $(".spin").show();

                // window.table._fnDraw();
                // disableNext();

                this.getHoursAvailableWithDate(this.valData);


            }
            else {


                this.$swal({
                    icon: 'error',
                    text: error,
                    timer: 2000,
                    showConfirmButton: false
                });


            }



        },



        isAdmin: function () {
            var b = this.$cookies.get("b");
            if (b == 'Y' || b == 'S') return true;
            else return false;

        },
        checkRo: function (field) {
            console.log(field);
            return false;
            /*   var b = this.$cookies.get("b");
              if (b == 'Y' || b == 'S') return false;

              console.log(field); */
        },


        getUrlDomain: function (nome_metodo) {

            let result = "";

            if ($(location).attr('hostname') == "localhost") {
                result = $(location).attr('protocol') + "//emtool.local/";
            }

            if ($(location).attr('hostname') != "localhost") {

                result = $(location).attr('protocol') + "//" + $(location).attr('hostname') + "/";

            }

            return result + "public/api/smartbook/" + nome_metodo;

        }

        ,

        getBasePath: function () {

            let result = "";

            if ($(location).attr('hostname') == "localhost") {
                result = $(location).attr('protocol') + "//emtool.local/";
            }

            if ($(location).attr('hostname') != "localhost") {

                result = $(location).attr('protocol') + "//" + $(location).attr('hostname') + "/";

            }

            return result;

        },





        setupButtons: async function () {
            setTimeout(() => {

                var pulsantis = [];

                this.$root.$children[0].bnavbuttons = pulsantis;

            }, 100);
        }

        ,

        syncProfile: async function () {

            //Sync profile
            /*  var token = window.$cookies.get("token");
        var system = window.$cookies.get("system"); */

            //  this.data.days = response.data ;
        }

        ,

        btn_add_tms_click() {
            console.log(1);
        }

        ,
        /*          onClick() {
      console.log('a phone number was clicked');
    }
,
 */
        reply(par) {
            console.log(par);
        }

        ,

        reset() {
            this.$refs.form.reset();
        }

        ,
        resetValidation() {
            this.$refs.form.resetValidation();
        }

        ,

        createPdf: async function () {

            var that = this;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            // valori.currDayTo = window.currDayTo;
            // valori.curStatus = window.curStatus;
            // valori.curPiano = window.curPiano;

            var response = await apibookingrsa.getPdfPrenotazioni("ZmFnb3N0b25p",
                3165,
                window.currDay,
                window.currDayTo,
                window.curStatus,
                window.curPiano,
                ""

            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getPdfPrenotazioni", res);

                try {

                    console.log(res.data.Result);

                    window.open(res.data.Result, '_blank');

                } catch (error) {
                    console.log(error);
                }

            }

            ).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                }

                );
                console.log("ERrori", "Non è stato possibile esportare il pdf");
                console.log("response", response);

            }

            );

        }

    }

    ,
}

    ;
</script>

<style>
.clColLogo {
    transform: translateX(-14px) !important;
}

.clColTitle {
    transform: translateX(-14px) !important;
}


.errorMessageNome {
    color: red;
    font-weight: bold;
    font-size: 12px;
    margin-left: 10px;
    margin-top: -12px;
}

.errorMessagePhone {
    color: red;
    font-weight: bold;
    font-size: 12px;
    margin-left: 10px;
    margin-top: -12px;
}

.errorMessageMail {
    color: red;
    font-weight: bold;
    font-size: 12px;
    margin-left: 10px;
    margin-top: -12px;
}

.errorMessageNumPersone {
    color: red;
    font-weight: bold;
    font-size: 12px;
    margin-left: 10px;
    margin-top: -12px;
}


.contpopupdate .theme--light.v-text-field>.v-input__control>.v-input__slot:before {
    border-color: #c39d63;
}


#fldInpData2 {
    color: white !important;
    font-size: 12px !important;
}


/* #data {
    color: white !important;
    font-size: 12px !important;
    background-color: black !important;
    border: 1px solid #c39d63 !important;
} */

/* #giorno {
    color: white !important;
    font-size: 12px !important;
    background-color: black !important;
    border: 1px solid #c39d63 !important;
}

#ora {
    color: white !important;
    font-size: 12px !important;
    background-color: black !important;
    border: 1px solid #c39d63 !important;
} */

#name {
    color: white !important;
    font-size: 12px !important;
    background-color: black !important;
    border: 1px solid #c39d63 !important;
}

#phone {
    color: white !important;
    font-size: 12px !important;
    background-color: black !important;
    border: 1px solid #c39d63 !important;
}

#email {
    color: white !important;
    font-size: 12px !important;
    background-color: black !important;
    border: 1px solid #c39d63 !important;
}

#num_persons {
    color: white !important;
    font-size: 12px !important;
    background-color: black !important;
    border: 1px solid #c39d63 !important;
}

#note {
    color: white !important;
    font-size: 12px !important;
    background-color: black !important;
    border: 1px solid #c39d63 !important;
}






.val_select {
    width: 10px;
    accent-color: #c39d63;
}

.clListOrari {
    overflow-y: auto;
    max-height: 180px;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.clListOrari::-webkit-scrollbar {
    width: 12px;
}

.clListOrari::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.clListOrari::-webkit-scrollbar-thumb {
    background: #c39d63;
    border-radius: 6px;
}

.clListOrari::-webkit-scrollbar-thumb:hover {
    background: #c39d63;
}

.colHeaderCompositeOrariDisp {
    display: flex;
    justify-items: center;
    align-items: center;
    width: 70px;
    color: white;
    font-weight: bold;
    font-size: 12px;
}



.rowOrariDisp {
    display: flex !important;
    height: 35px !important;
    align-items: top !important;
    background-color: black;
}

.headerOrariDisp {
    display: flex !important;
    height: 12px !important;
    align-items: top !important;
    background-color: black;
    height: 40px !important;
}


.colRadioOrariDisp {
    display: flex;
    justify-content: center;
    justify-items: center;
    align-items: center;
    width: 40px;
}

.colCompositeOrariDisp {
    display: flex;
    justify-items: center;
    align-items: center;
    width: 70px;
    color: white;
    font-size: 12px;
}

.colTipoOrariDisp {
    display: flex;
    justify-items: center;
    align-items: center;
    width: 50px;
    color: white;
    font-size: 12px;
}

.colHeaderTipoOrariDisp {
    display: flex;
    justify-items: center;
    width: 50px;
    color: white;
    font-weight: bold;
    font-size: 12px;
    align-items: center;
}

.colMessaggioOrariDisp {
    display: flex;
    justify-content: center;
    justify-items: center;
    align-items: center;
    width: 80px;
    color: white;
    font-size: 12px;
}

.colHeaderMessaggioOrariDisp {
    display: flex;
    justify-items: center;
    width: 80px;
    color: white;
    font-weight: bold;
    font-size: 12px;
}




.rowOrari {
    background-color: black;
    ;
}

.clButtonMiddle2 {}

.clButtonLeft {
    float: left;
}

.wrap_buttons_footer2 {
    min-width: 100vw !important;
    text-align: center !important;
}



.mainbookinggen input[type="radio"] {
    accent-color: #c39d63 !important;
    width: 30px !important;
}

.clDateValue {
    margin-left: 13px;
    font-weight: bold;
    transform: translateX(0px);
}


.clDescDateValue {
    margin-left: 13px;
    font-weight: bold;
    transform: translateX(0px);
}


.clDateLabel {
    font-weight: bold;
    transform: translateX(736px);
}

.val_select {
    width: 10px;
}


/* #btn_middle {
    transform: translateX(23px) !important;
} */

/* #btn_middle2 {
    transform: translateX(-31px) !important;
} */

/* #btn_left {
    transform: translateX(47px) !important;
} */

.v-overflow-booking {

    overflow-y: auto !important;
    padding-bottom: 115px !important;
    max-height: 100vh !important;
    overflow-x: hidden;
}


.mainbookinggen .spin {
    top: 50% !important;
    z-index: 99999 !important
}

.clTitleContainer {
    display: table !important;
    justify-content: center !important;
    align-items: center !important;
    justify-items: center !important;
    min-width: 100% !important;
    flex-direction: column !important;
    min-height: 100% !important;
}

.clTitleContainer div {
    font-family: 'Playfair Display', Georgia, "Times New Roman", serif !important;
    font-size: 60px !important;
    color: #ffffff !important;
    line-height: 1.2em !important;
}

.clTitleContainer img {
    width: 83px;
}


.clTitleText {
    min-width: 100% !important;
    display: flex !important;
    justify-content: center !important;
    align-items: flex-start !important;
    margin-left: -211px !important;
    min-height: 100% !important;
    margin-top: 83px;
}

.clTitleText div {
    font-family: 'Playfair Display', Georgia, "Times New Roman", serif !important;
    font-size: 73px !important;
    color: #ffffff !important;
    line-height: 1.2em !important;
}


.clTitleLogo {
    min-width: 100% !important;
    display: flex !important;
    justify-content: center !important;
    align-items: flex-start !important;
    margin-left: -211px !important;
    min-height: 100% !important;
    margin-top: 83px;
}

.clTitleLogo div {
    font-family: 'Playfair Display', Georgia, "Times New Roman", serif !important;
    font-size: 73px !important;
    color: #ffffff !important;
    line-height: 1.2em !important;
}



header {
    background: transparent !important;
}

.mainbookinggen {
    padding-top: 450px;
}

.headerTop {
    background: transparent !important;
    background-color: transparent !important;
}

.swal2-container {
    z-index: 100000;
}


.titlenewrow {
    justify-content: center;
    padding-top: 25px;
    padding-bottom: 30px;
    font-weight: bolder;
}

.v-sheet.v-card:not(.v-sheet--outlined) {
    box-shadow: none;
}

.pay {
    display: flex;
    justify-content: center;
    padding-top: 10px;
    color: #f77002;

}

.pay a {
    color: #f77002;

    -webkit-transition: background-color 2s ease-out;
    -moz-transition: background-color 2s ease-out;
    -o-transition: background-color 2s ease-out;
    transition: background-color 2s ease-out;

}

.pay a:hover {
    color: #d56e1a;
}

.plan {
    margin-top: 20px;
    font-size: 12px;
    display: flex;
    justify-content: center;

}

.policy {
    text-align: center;
    margin-top: 30px;
}

.version {
    font-size: 10px;
    margin-top: 25px;
    text-align: center;
}

.version span {
    font-size: 13px;
}

.titleproduct {
    font-size: 16px;
    font-size: 16px;
    color: rgb(245, 207, 46);
    font-weight: 800;

    text-align: center;
    margin-top: 40px;

}

.Absolute-Center {

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    bottom: 0;
    height: 100px;
    left: 0;
    margin: auto;
    position: absolute;
    top: 0;
    right: 0;
    width: 300px;
}

.logo {
    text-align: center;
}

.cont_booking_rsa .table-responsive {}

ion-items {
    min-width: 60px;

}

ion-items ion-datetime {
    margin-left: 25px;
}

.colcontTable .col-sm-12 {
    padding:
        0px;
}

.selectpianiall {
    max-width: 21px;
    min-height: 22px;
    margin-left: 0px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    /* border-radius: 12px; */
    /* min-height: 12px; */
    /* transform: translateY(-2px); */
    background-image: url(/smartb/img/FiltroNucleo.png);
    /* padding: 1px 11px; */
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    transform: translateY(2px);

}

.frmSearch {
    border: 1px solid #a8d4b1;
    background-color: #c6f7d0;
    margin: 2px 0px;
    padding: 40px;
    border-radius: 4px;
}

#search-box {
    padding: 10px;
    border: #a8d4b1 1px solid;
    border-radius: 4px;
}

#country-list {
    float: left;
    list-style: none;
    margin-top: -3px;
    padding: 0;
    width: 190px;
    position: absolute;
    z-index: 1;
}

#country-list li {
    padding: 10px;
    background: #f0f0f0;
    border-bottom: #bbb9b9 1px solid;
}

#country-list li:hover {
    background: #ece3d2;
    cursor: pointer;
}

.swiper-container {
    max-width: 100vw !important;

    overflow: hidden;
    margin: 0 auto;
    position: relative;
}

.swiper-slide {

    overflow-y: auto;
    overflow-x: hidden;
}

.swiper-slide img {
    display: block;
    width: 100%;
}

.datatable tbody tr:hover {
    background-color: #f5f5f5;
}

.sorting_1 input[type="checkbox"] {
    top: 0px;
}

.contDate {
    display: flex;
}

.overf {
    overflow: visible !important;
}

.contDate input {
    max-width: 98px !important;
    min-width: auto;
}


.cont_book_pasq2 table thead th {
    border-bottom: transparent !important;
}

.cont_book_pasq2 table td {
    border-top: transparent !important;
    border-bottom: transparent !important;
}


.cont_book_pasq2 .dataTable tbody tr,
.cont_book_pasq2 .dataTable tbody tr td {
    vertical-align: middle !important;
    padding: 6px !important;
}

.contInputs {
    display: flex;
}

.contInputs input,
.p_total_ordinario {
    max-width: 60px;
    min-width: 95px;
    font-weight: 800;
    text-align: right;
}

/*         .table-responsive {
                    min-width: 500px;
                    max-height: 65vh;
                } */
.step {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    list-style: none;
    margin: .2rem 0;
    width: 100%;
}

.step .step-item a {
    color: #5755d9;
    display: inline-block;
    padding: 20px 10px 0;
    text-decoration: none;
}

.con {

    display: flex;
    text-align: center;
}

.buttons {
    display: flex;
    justify-content: center;
}

.bootstrap-datetimepicker-widget {
    /*   top: 20;
              right: 20;
              left:300; */
    z-index: 99999 !important;
    border-radius: 4px;
    right: 400px;
}

.dataTable tbody tr td {
    overflow: visible !important;
}

@media only screen and (max-width: 768px) {

    /*  .swiper-container {
                max-width: 500px;
            } */
    .cont_booking_rsa .table-responsive {
        padding: 0px 26px;
        /* background:yellow;*/
        margin: 0;
    }
}
</style>

<style>
.clButtonMiddle {}

/* #myTableBook .cont_buttons {
    position: relative;
} */

#myTableBook .text {
    padding-left: 4px;
    font-size: 10px;
    position: absolute;
    top: 0px;
    left: 0px;
}

#myTableBook_filter {
    position: relative;
    background-image: inherit !important;
    background: #ee781d;
    border: 1px solid black;
    align-items: center;
    display: flex;
    justify-content: space-between;

}

#myTableBook_filter label {
    display: none;
}

#myTableBook_filter {
    min-height: 46px;
}

.vcentered {
    height: 100%;
    display: flex;
    align-items: center;
    min-height: 79vh;
    justify-content: center;

    background-repeat: no-repeat;
}

[data-status="0"] img {
    cursor: default !important;
}

.active {
    background: #efebeb;
}

.val_statuses2 img {
    max-width: 26px;
    transform: translateX(-5px);
    /* cursor: pointer; */
}

.val_statuses_disable img {
    max-width: 26px;
    transform: translateX(-5px);
}



.swiper-slide {
    width: 100% !important;
}

.gj-picker-md [role=switch],
.gj-picker-md [role=header] {
    background: #f3832d !important;
}

#booking_date_time {
    background-color: white;
    min-width: 100%;
}

.gj-datepicker-md {
    min-width: 100%;
}

.gj-datepicker-md [role=right-icon] {
    top: 4px !important;
}

.form-rows {
    margin-bottom: 24px;
}

#notifiche,
.clsecurity,
#footer-info {
    display: none !important;
}

.smallTitle {
    color: white;
    background: #ec771e;
    color: white;

    min-width: 100%;
    padding-left: 15px;
    margin-bottom: 5px
}

.top-bar {
    z-index: 2;
}

.lang_cont,
#item_supp,
#item_sett {
    display: none !important;
}

/* .swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
    left: -7px;
    color: #ec771e;

    zoom: 0.8;
    -moz-transform: scale(0.8);
    -moz-transform-origin: 0 0;
    -o-transform: scale(0.8);
    -o-transform-origin: 0 0;
    -webkit-transform: scale(0.8);
    -webkit-transform-origin: 0 0;
    transform: scale(0.8);

    transform-origin: 0 0;

}

.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
    right: -7px;
    color: #ec771e;

    zoom: 0.8;
    -moz-transform: scale(1);
    -moz-transform-origin: 0 0;
    -o-transform: scale(0.8);
    -o-transform-origin: 0 0;
    -webkit-transform: scale(0.8);
    -webkit-transform-origin: 0 0;
    transform: scale(0.8);

    transform-origin: 0 0;

} */

.swiper-cube-shadow {
    display: none;
}

.mcontent {
    padding-bottom: 0;
}

.page-link {
    display: none !important;
}

.swiper-container-horizontal>.swiper-pagination-bullets,
.swiper-pagination-custom,
.swiper-pagination-fraction {
    bottom: -5px;
}

.swal-text input {
    max-width: 46px;
    border: 1px solid #d0c9c9;
    text-align: center;
    font-size: 12px;

}

.swal-button-container {
    cursor: pointer;
}

.swal-title {
    font-size: 16px;
}

.cont_ticketss {
    max-width: 580px;
    /*   max-height: 60vh; */
    margin: 0 auto;
    position: relative;
}

#myTableBook_wrapper {
    max-height: 83vh !important;
}

@media only screen and (min-width: 900px) {

    .grid {
        max-height: 63vh;
    }
}

@media only screen and (max-width: 650px) {

    /*  .cont_booking_rsa    .swiper-container {
        min-width: 100vw;
        transform: translate(-3.8vw, -21px);
        padding: 0;
        margin: 0;

    }

   .cont_booking_rsa  .swiper-wrapper,
   .cont_booking_rsa  .table-responsive {
        padding: 0;
        margin: 0;
        padding: 0 !important;
        margin: 0 !important;
    }

  .cont_booking_rsa   .table-responsive {
        padding: 0px 26px;

        margin: 0;
    } */
}

.numRow {
    padding: 10px;
}

.text {
    padding-left: 4px;
    font-size: 10px;
    position: absolute;
    top: -1px;
    left: 10px;
}
</style>
<style>
.title-table {
    text-align: left;
    padding-left: 10px;
}

.two {
    min-height: 600px;
}

.three {
    min-height: 550px;
}

.four {
    min-height: 550px;
}

.fifth {
    min-height: 95vh;
}

.six {
    min-height: 1100px;
}

.cont_buttons {
    /* display: inline-block; */
    float: left;
    min-width: 10%;
}

.cont_booking_rsa .table-responsive {
    min-height: 65vh;
    overflow-x: hidden;
    padding: 0px 10px;
    box-shadow: none;
    padding-bottom: 150px;
    /* min-width: 70vw; */
}

.sel_banca .select2 {
    min-width: 200px;
    max-width: 200px;
}
</style>
<style>
.cont_action {
    display: none;
}

div.dataTables_wrapper div.dataTables_filter input {
    padding: 0;
    padding-left: 10px;
}

.container .select2-container {
    min-width: 100%;
}

.container .select2-container .select2-selection--single {
    min-width: 90px;
}

.exitall {
    display: none;
}

@media only screen and (max-width: 768px) {
    .cont_booking_rsa .table-responsive {
        /*   max-width: 72vw !important;
                        min-width: 72vw !important; */
        /*padding: 0px 30px;*/
        /*   overflow-x: auto; */
    }

    .right {
        right: -48px;
    }

    .left {
        left: -48px;
    }

    .clDateValue {
        font-weight: bold;
        transform: translateX(0px);

    }

    .clDateLabel {
        font-weight: bold;
        transform: translateX(-102px);
    }

    .wrap_buttons_footer2 {
        display: block !important;
        text-align: center !important;
        min-width: 100vw !important;
    }

    .clButtonMiddle2 {
        transform: translateX(-39px);
    }


    .clButtonMiddle {
        transform: translateX(19px);
    }


}

.cont_book_pasq2 #myTableBook {

    width: auto !important;
    min-width: 400px !important;
    margin: 0 auto !important;
    margin-top: 49px !important;
}

.cont_book_pasq2 #myTableBook_filter {
    left: 0px;
    position: absolute;
    top: 0px;
    max-width: 100%;
    min-width: calc(100% - 0px);
    ;
}

.booking_gen .calendarButton {
    cursor: pointer !important;
    width: 25px !important;
    margin-left: -180px !important;
}

@media screen and (max-width: 768px) {

    .cont_booking_rsa #myTableBook {

        min-width: 90vw !important
    }

    #myTablebook_wrapper {
        box-sizing: border-box;
        overflow-x: auto;
        margin: 0 auto;
    }


    .booking_gen .calendarButton {
        cursor: pointer !important;
        width: 25px !important;
        /* margin-left: -203px !important; */
    }

    .mainbookinggen {
        padding-top: 261px;
    }

    .clTitleText {
        min-width: 100% !important;
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
        margin-left: -211px !important;
        min-height: 100% !important;
    }

    .clTitleText div {
        font-family: 'Playfair Display', Georgia, "Times New Roman", serif !important;
        font-size: 50px !important;
        color: #ffffff !important;
        line-height: 1.2em !important;
    }

    .clTitleLogo {
        min-width: 100% !important;
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
        margin-left: -211px !important;
        min-height: 100% !important;
    }

    .clTitleLogo div {
        font-family: 'Playfair Display', Georgia, "Times New Roman", serif !important;
        font-size: 50px !important;
        color: #ffffff !important;
        line-height: 1.2em !important;
    }


    #btn_left {
        transform: translateX(10px) !important;
    }


    .cont_book_pasq2 .cont_buttons {
        display: flex !important;
        align-items: center !important;
        min-width: 321px !important;
        position: relative !important;
        justify-content: center !important;
    }

    .clTitleContainer {
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
        justify-items: center !important;
        min-width: 100% !important;
        flex-direction: column !important;
        min-height: 100% !important;
    }

    .clTitleContainer div {
        font-family: 'Playfair Display', Georgia, "Times New Roman", serif !important;
        font-size: 40px !important;
        color: #ffffff !important;
        line-height: 1.2em !important;
    }

    .clTitleContainer img {
        width: 83px;
    }


    .clColLogo {
        transform: translateX(-14px) !important;
    }

    .clColTitle {
        transform: translateX(-14px) !important;
    }



}

#myTableBook {
    max-width: 300px;
}

.cont_booking_rsa #myTableBook_wrapper {
    margin-top: 1px;
}

#myTableBook {
    min-width: 300px;
}

.form-group.centered {
    min-width: 100%;
    text-align: center;
}

.swipeAnagrafica {
    padding-top: 20px !important;

    padding-bottom: 150px !important;
    overflow-y: auto;
    max-height: 100vh;

}

.swipeAnagrafica .form-group label {
    padding-left: 16px;
    margin: 0px;
}

.swipeAnagrafica .form-control {
    font-size: 12px;
}

.contanagrafica {
    max-width: 400px;
    margin: 0 auto;
    padding-top: 16px;
}

.cont_booking_rsa .smallTitle {
    min-width: 100vw;

    min-width: 100%;
    border: 1px solid black;
    max-width: 100%;
    position: absolute;
    top: 13px;
    min-height: 46px;
    left: 12px;

}

.titleAnagrafica {
    font-size: 12px;
    padding-left: 3px;
    font-weight: bold;
    display: flex;
    align-items: center;
}

.cont_book_pasq2 .cont_buttons {
    display: flex;
    align-items: center;
    min-width: 321px;
    position: relative;
    justify-content: center;
    margin-left: 15px;
    ;
}


.cont_book_pasq2 .text {
    top: 2px;
}

.daySelected {
    position: absolute;
    font-size: 11px;
    display: flex;
    justify-content: center;
    min-width: 100%;
    top: -18px;
}


.expetion {
    z-index: 3;
    position: fixed;
    /* background: white; */
    min-width: 100%;
    min-height: 45vh;
    display: flex;
    justify-content: center;
    font-size: 14px;
    /*  background: red; */
    margin-top: 7px;
    left: 0px;
    padding-top: 10px;
    align-items: flex-end;
}

.wrapexpetion {
    text-align: center;
}

.staffpasq {
    font-size: 12px;
}
</style>
