import { render, staticRenderFns } from "./bookinggenTest.vue?vue&type=template&id=54695510&"
import script from "./bookinggenTest.vue?vue&type=script&lang=js&"
export * from "./bookinggenTest.vue?vue&type=script&lang=js&"
import style0 from "./bookinggenTest.vue?vue&type=style&index=0&lang=css&"
import style1 from "./bookinggenTest.vue?vue&type=style&index=1&lang=css&"
import style2 from "./bookinggenTest.vue?vue&type=style&index=2&lang=css&"
import style3 from "./bookinggenTest.vue?vue&type=style&index=3&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VCol,VDatePicker,VImg,VList,VMenu,VRow,VTextField})
