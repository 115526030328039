<template>
    <div class="cont-table">

        <div class="spin">
            <ion-spinner name="crescent"></ion-spinner>
        </div>

        <div class="row cont_tickets contListBookingGenNew">
            <div class=" " style="margin:0 auto;">
                <div class="card">

                    <div class="card-body" style="padding:0px">

                        <div class="table-responsive" style="">

                            <table id="myTable" style="margin-top:57px">
                                <thead>
                                    <tr>

                                        <th class='all' style='text-align:right!important;'> </th>

                                        <th class='all'>Nominativo</th>

                                        <th class='all'>
                                            <div class="clHeaderOraData">Ora / Data</div>
                                        </th>

                                        <th class='desktop'>
                                            <div class="clHeaderPersone">Persone</div>
                                        </th>

                                        <th class='all'>Stato</th>

                                        <th class='desktop'> E-Mail</th>
                                        <th class='desktop'>
                                            <div class="clHeaderTelefono">Telefono</div>
                                        </th>

                                        <th class='none'> Note</th>
                                        <th class='desktop'> Descrizione</th>
                                        <th style="text-align:center;" class='desktop'>Device</th>

                                    </tr>
                                </thead>

                                <tfoot>

                                    <tr>
                                        <th colspan="10">
                                        </th>
                                    </tr>
                                </tfoot>

                            </table>

                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="contpopupsearch" style="display:none">

            <template>
                <modal name="popupSearch" :clickToClose="false" :width="262" :height="215">


                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important;font-weight:bold;margin-left:10px">

                            <v-img alt="" src="@/assets/smartbook_ricerca3.png" max-width="25" max-height="25" />

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <v-text-field v-model="textSearch" label="">
                            </v-text-field>

                        </v-col>

                    </v-row>



                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-search" contain src="@/assets/btn_cancel3_smartbook.png"
                                    max-width="30" max-height="30" style="cursor:pointer;color:black;margin-right:10px"
                                    transition="scale-transition" title="Annulla" @click="btnCancelSearch" />

                                <v-img alt="" class="btn-confirm-search" contain
                                    src="@/assets/btn_confirm3_smartbook.png" max-width="30" max-height="30"
                                    style="cursor:pointer" transition="scale-transition" title="Conferma"
                                    @click="btnConfirmSearch" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>

        <div class="contpopupdate" style="display:none">

            <template>
                <modal name="popupDate" :clickToClose="false" :width="300" :height="322">


                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important;font-weight:bold;margin-left:10px">

                            Data

                        </v-col>

                    </v-row>



                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important">

                            <v-menu v-model="showPicker" :close-on-content-click="false" transitions="scale-transition"
                                offset-y max-width="290px" min-width="290px">
                                <template v-slot:activator="{ on }">
                                    <v-text-field id="fldInpData" v-model="dataDa" label="Dal" persistent-hint readonly
                                        v-on="on"></v-text-field>
                                </template>
                                <v-date-picker v-model="dataDa" no-title @input="showPicker = false" locale="it">
                                </v-date-picker>
                            </v-menu>

                        </v-col>

                    </v-row>


                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important">

                            <v-menu v-model="showPicker2" :close-on-content-click="false" transitions="scale-transition"
                                offset-y max-width="290px" min-width="290px">
                                <template v-slot:activator="{ on }">
                                    <v-text-field id="fldInpData" v-model="dataA" label="Al" persistent-hint readonly
                                        v-on="on"></v-text-field>
                                </template>
                                <v-date-picker v-model="dataA" no-title @input="showPicker2 = false" locale="it">
                                </v-date-picker>
                            </v-menu>

                        </v-col>

                    </v-row>



                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-date" contain src="@/assets/btn_cancel3_smartbook.png"
                                    max-width="30" max-height="30" style="cursor:pointer;color:black;margin-right:10px"
                                    transition="scale-transition" title="Annulla" @click="btnCancelDate" />


                                <v-img alt="" class="btn-reset-date" contain src="@/assets/reset.png" max-width="22"
                                    max-height="22" style="cursor:pointer;margin-right:10px;margin-top:3px"
                                    transition="scale-transition" title="Pulisci" @click="btnResetDate" />

                                <v-img alt="" class="btn-confirm-date" contain src="@/assets/btn_confirm3_smartbook.png"
                                    max-width="30" max-height="30" style="cursor:pointer" transition="scale-transition"
                                    title="Conferma" @click="btnConfirmDate" />

                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>



    </div>
</template>

<script>
import apibookingrsa from "../utils/bookingrsa/apibookingrsa";
import apiparenti from '../../../smartbsrc/src/utils/profile/apiparenti';

import {
    bus
} from "../main";
import router from ".././router";
//import $ from 'jquery';

export default {
    destroyed() {
        this.$root.$children[0].bnavbuttons = [];

        bus.$off("bottomIconClickEvent");
    },

    mounted: function () {
        /*     let self = this;
        fetch('https://jsonplaceholder.typicode.com/posts')
          .then(response => response.json())
          .then(json => self.$data.items = json) */

        //var res = apiprofile.getProfile(1,2);

        /*eslint-disable no-undef*/

        this.$root.$children[0].checkLogout();

        //var that = this;

        window.token = window.$cookies.get("token");

        var settSmartbook = JSON.parse(window.$cookies.get("settSmartbook"));

        window.cid = settSmartbook[0].azienda_id;

        var is_admin = window.$cookies.get("b");
        window.is_admin = is_admin;

        console.log("WINDOW.IS_ADMIN 1: ", window.is_admin);

        var is_su = window.$cookies.get("a");
        window.is_su = is_su;

        window.curSrcText = "";


        if (window.is_admin == 'Y' || window.is_admin == 'S') window.is_admin = 1;

        console.log("WINDOW.IS_ADMIN 2: ", window.is_admin);

        var b = window.$cookies.get("b");
        var $rotta = "";
        if (b == "S" || b == 'Y') {
            $rotta = "/listBookingRsa";

        } else {
            $rotta = "/bookingRsa";

            setTimeout(() => {
                router.push({
                    path: $rotta
                });

            }, 300);

        }
        console.log($rotta);


        bus.$off("bottomIconClickEvent");

        window.perpage = 20;

        window.curSrcText = "";

        window.curStatus = "0";

        const today = new Date();
        // const formattedDate = `${today.getFullYear()}-${String(today.getDate()).padStart(2, '0')}-${String(today.getMonth() + 1).padStart(2, '0')}`;

        const formattedDate = `${today.getFullYear()}-${String(today.getMonth() + 1).padStart(2, '0')}-${String(today.getDate()).padStart(2, '0')}`;


        window.currDay = formattedDate;

        window.currDayTo = formattedDate;

        //var b = window.$cookies.get("b");
        //var $rotta = "";
        if (b == "S" || b == 'Y') {
            $rotta = "/listBookingRsa";

        } else {
            $rotta = "/bookingRsa";

            //  alert ( $rotta );

            setTimeout(() => {
                router.push({
                    path: $rotta
                });

            }, 300);

            //     this.$router.go( $rotta );
            //   return false ;
        }
        console.log($rotta);

        $(document).ready(
            function () {

                $("body").off();

                window.uname = window.$cookies.get("username");
                var uname = window.uname;

                const args = [uname, true, ['bar', 5], {
                    foo: 'baz'
                }];
                try {
                    console.log("entering user");
                    window.flutter_inappwebview.callHandler('setLoggedUser', ...args);
                    console.log("passed");

                } catch (error) {
                    // alert(error);
                }

            }
        );

        $(document).ready(function () {


            $('body').on('click', '#footer-link', function (event) {
                event.preventDefault();

                const args = [1, true, ['bar', 5], {
                    foo: 'baz'
                }];
                window.flutter_inappwebview.callHandler('openLcHandler', ...args);

                return false;
            });

            $('body').on('click', '#privacy-link', function (event) {
                event.preventDefault();

                const args = [1, true, ['bar', 5], {
                    foo: 'baz'
                }];
                window.flutter_inappwebview.callHandler('openPrivacyHandler', ...args);

                return false;
            });

            $('body').on('click', '#hdtool-link', function (event) {
                event.preventDefault();

                const args = [1, true, ['bar', 5], {
                    foo: 'baz'
                }];
                window.flutter_inappwebview.callHandler('openHdHandler', ...args);

                return false;
            });

            // $('body').on('click', '.val_status', function () {

            //     console.log("VAL_STATUS");

            //     // var is_select = 0;

            //     // var chk = $(this).attr("aria-checked");

            //     // if (chk == "false") {
            //     //     is_select = 1;
            //     // }

            //     // if (is_select == 0)
            //     // {

            //     // }

            //     // if (is_select == 1)
            //     // {
            //     //     pointerVue.sl
            //     // }

            //     // var id_guest = $(this).val();

            //     // pointerVue.updAssocGuest(is_select, id_guest);

            // });

        });

        setTimeout(() => {
            this.syncProfile();
        }, 100);
        // console.log (res);

        bus.$on("bottomIconClickEvent", (data) => {
            //this.header = data;
            console.log(data);
            switch (data) {

                case "btn_delete":

                    if (window.id_par_sel == 0) {

                        this.$swal({
                            icon: "error",
                            text: "Selezionare la riga da eliminare",
                            showConfirmButton: false,
                            timer: 2000
                        });

                    }

                    if (window.id_par_sel > 0) {

                        this.deleteRow();

                    }

                    break;

                case "btn_exit":
                    router.push({
                        path: "/dash"
                    });
                    break;

                case "btn_add":
                    router.push({
                        path: "/editParente/0"
                    });
                    break;

                case "btn_edit":

                    //alert(window.id_par_sel);

                    if (window.id_par_sel == 0) {

                        this.$swal({
                            icon: "error",
                            text: "Selezionare la riga da modifcare",
                            showConfirmButton: false,
                            timer: 2000
                        });

                    }

                    if (window.id_par_sel > 0) {

                        router.push({
                            path: "/editParente/" + window.id_par_sel
                        });

                    }

                    break;

                case "btn_unisci":

                    var ids_selected = "";
                    $('.val_status').each(function () {

                        var is_select = 0;

                        var chk = $(this).attr("aria-checked");

                        if (chk == "true") {
                            is_select = 1;
                        }

                        if (parseInt(is_select) == 1) {

                            if (ids_selected != "") {
                                ids_selected = ids_selected + ",";
                            }

                            ids_selected = ids_selected + $(this).val();

                        }

                    });

                    //console.log("ids_selected: ", ids_selected);

                    this.unisciParenti(ids_selected);

                    break;

                default:
                    break;

            }
        });

        // this.setupButtons();

        this.setupButtonsGrid();

        window.dataset = [];
        this.setupGrid();
        this.hideShowButtons(false);

        this.$root.$children[0].bnavbuttons = [];

    },
    data: () => ({

        dataDa: null,
        dataA: null,

        showPicker2: false,



        textSearch: "",

        countChkSelected: 0,

        showPicker: false,
        profile: {},
        valid: true,
        initials: "",
        namesurname: "",
        iban: "",
        cellulare: "",
        codicefiscale: "",
        codicefiscaleRules: [
            (v) => !!v || "Codice fiscale is required",
            (v) =>
                (v && v.length <= 16) ||
                "Codice fiscale must be less than 16 characters",
        ],
        cellulareRules: [
            (v) => !!v || "Phone number is required",
            (v) =>
                (v && v.length <= 30) || "Phone number must be less than 15 characters",
        ],
        genRules: [(v) => !!v || "Field required"],
        ibanRules: [
            (v) => !!v || "Iban is required",
            (v) => (v && v.length <= 30) || "Iban must be less than 30 characters",
        ],
        email: "",
        emailRules: [
            (v) => !!v || "E-mail is required",
            (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
        ],
        select: null,
        items: ["Item 1", "Item 2", "Item 3", "Item 4"],
        checkbox: false,
    }),

    methods: {

        getUrlDomainApi: function (nome_metodo) {

            let result = "";

            if ($(location).attr('hostname') == "localhost") {
                result = $(location).attr('protocol') + "//emtool.local/";
            }

            if ($(location).attr('hostname') != "localhost") {

                result = $(location).attr('protocol') + "//" + $(location).attr('hostname') + "/";

            }

            return result + nome_metodo;

        },


        btnConfirmDate: function () {

            this.$modal.hide('popupDate');

            console.log("DATA DAL: ", this.dataDa);
            console.log("DATA AL: ", this.dataA);

            window.currDay = this.dataDa;

            window.currDayTo = this.dataA;

            this.showSpinner = true;
            this.$root.$children[0].showProgress = true;

            window.table.ajax.reload();


        },

        btnCancelDate: function () {

            this.$modal.hide('popupDate');

        },

        btnResetDate: function () {

            this.dataDa = null;
            this.dataA = null;

        },

        getUrlDomainGo: function (nome_metodo) {

            let result = "";

            if ($(location).attr('hostname') == "localhost") {
                result = "http://localhost:4006/";
            }

            if ($(location).attr('hostname') != "localhost") {

                result = "https://filestransfer.it:4006/";

            }

            return result + nome_metodo;

        },

        btnCancelSearch: function () {

            this.$modal.hide('popupSearch');

        },

        btnConfirmSearch: function () {

            console.log("ok");

            window.curSrcText = this.textSearch;

            this.$modal.hide('popupSearch');

            this.showSpinner = true;
            this.$root.$children[0].showProgress = true;

            setTimeout(function () {

                window.table.ajax.reload();

            }, 200);


        },


        unisciParenti: async function (ids_selected) {


            var that = this;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiparenti.mergeParenti(
                ids_selected

            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from mergeParenti", res);

                if (res.data.Result == "OK") {

                    that.$swal({
                        icon: "success",
                        text: "Operazione completata con successo",
                        showConfirmButton: false,
                        timer: 2000
                    });

                    setTimeout(() => {

                        that.dialogMsg = false;
                        that.setupButtons();

                        window.location.reload();

                    }, 200);

                }
                else {


                    that.$swal({
                        icon: "error",
                        text: res.data.Errore,
                        showConfirmButton: false,
                        timer: 2000
                    });


                }



            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );



        },

        getUrlDomain: function (nome_metodo) {

            let result = "";

            if ($(location).attr('hostname') == "localhost") {
                result = $(location).attr('protocol') + "//emtool.local/";
            }

            if ($(location).attr('hostname') != "localhost") {

                result = $(location).attr('protocol') + "//" + $(location).attr('hostname') + "/";

            }

            return result + "public/api/smartbook/" + nome_metodo;

        },

        setupGrid: function () {

            // var that = this;
            //  var pathLocal = that.$withBase('/');

            var pointerVue = this;

            var pathLocal = pointerVue.$withBase('/');

            //var dateTest = new Date();
            //dateTest = dateTest.setDate(dateTest.getDate() + 365);
            //const dateTest2 = new Date(dateTest).toJSON().slice(0, 10);

            //console.log("DATE: " + dateTest2) //2015-07-23

            const date = new Date().toJSON().slice(0, 10)

            //console.log("DATE: " + date) //2015-07-23

            //  var $futureTime = dateTest2;

            $(document).ready(function () {

                var v_token = window.$cookies.get("token");


                window.columns = [{
                    data: 'DT_RowIndex',
                    orderable: false,
                    searchable: false,
                    name: '',
                    width: '1%',
                },

                {
                    data: 'composite',
                    orderable: false,
                    name: 'composite',
                    width: '13%'
                },

                {
                    data: 'booking_date_time',
                    orderable: false,
                    name: 'booking_date_time',
                    width: '16%',
                },

                {
                    data: 'num_persone',
                    orderable: false,
                    name: 'num_persone',
                    width: '10%'
                },
                {
                    data: 'status',
                    orderable: false,
                    name: 'status',
                    width: '13%'
                },

                {
                    data: 'email',
                    orderable: false,
                    name: 'email',
                    width: '5%'
                },
                {
                    data: 'phone',
                    orderable: false,
                    name: 'phone',
                    width: '5%'
                },

                {
                    data: 'note',
                    orderable: false,
                    name: 'note',
                    width: '6%'
                },
                {
                    data: 'description',
                    orderable: false,
                    name: 'description',
                    width: '6%'
                },
                {
                    data: 'tipology',
                    orderable: false,
                    name: 'tipology',
                    width: '1%'
                },

                ];
                window.table = $('#myTable').DataTable({
                    dom: "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6'f>>" +
                        "<'rows colcontTable'<'col-sm-12 'tr>>" +
                        "<'row'<'col-sm-12 col-ok  col-md-12'i><'col-sm-12 col-ok-2  col-md-12'p>>",
                    /*    "pagingType": "simple", */
                    responsive: true,
                    "pageLength": window.perpage,

                    processing: false,
                    paging: false,
                    serverSide: false,

                    ajax: {
                        url: pointerVue.getUrlDomainGo("getListingRSA") + '?v_user=' + v_token + '&id_azienda=' + window.cid,
                        data: function (valori) {

                            console.log("VALORI:", valori);

                            try {
                                window.table.clear().draw();

                            } catch (error) {
                                console.log(error);
                            }

                            window.start = window.perpage;

                            valori.start = 0;
                            valori.length = window.perpage;
                            valori.currDay = window.currDay;
                            valori.currDayTo = window.currDayTo;

                            valori.curStatus = window.curStatus;
                            valori.curPiano = window.curPiano;

                            valori.curSrcText = window.curSrcText;


                            window.valori = valori;
                            return valori;

                        }

                    },

                    language: {

                        lengthMenu: `<label>Mostra<select name="myTable_length" aria-controls="myTable" class="custom-select custom-select-sm form-control-sm"><option value="10">10</option><option value="25">25</option><option value="50">50</option><option value="100">100</option><option value="-1">Tutti</option></select>Inserimenti</label>`,

                        paginate: {
                            next: "<img style='height:15px;border:none' src='https://app.emtool.eu/_lib/img/next2.png'>",
                            previous: "<img style='height:15px;border:none' src='https://app.emtool.eu/_lib/img/previous2.png'>"
                        },

                        "sSearch": " ",
                        processing: '<svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66"><circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle></svg>'
                    },
                    "info": "",
                    "fnDrawCallback": function (oSettings) {

                        console.log(oSettings);

                        $("body").tooltip({
                            selector: '[data-toggle="tooltip"]'
                        });
                    },

                    columns: columns,

                    "initComplete": function (settings) {

                        console.log(settings);

                        $("#myTable_wrapper").show();
                        $(".spin").hide();

                    },
                    "drawCallback": function (settings) {

                        console.log(settings);

                        $(".spin").hide();

                        pointerVue.showSpinner = false;
                        pointerVue.$root.$children[0].showProgress = false;

                        var elements = $('.datetimeBook');
                        elements.each(function () {

                            var id = $(this).data("id");
                            (this).pickerOptions = {
                                buttons: [{
                                    text: 'Cancel',
                                    role: 'cancel',
                                    cssClass: 'switch-btn-cancel',
                                },
                                {
                                    text: 'Done',
                                    cssClass: 'switch-btn-done',
                                    handler: columns => {
                                        console.log("ore minuti id", columns.hour.text, columns.minute.text, id);
                                        var valore = columns.hour.text + ":" + columns.minute.text;

                                        console.log(valore);

                                        $.ajax({
                                            url: pointerVue.getUrlDomain("updateHour"),
                                            data: {
                                                "id": id,
                                                "hour": valore,
                                                "v_user": "ZmFnb3N0b25p"
                                            },
                                            success: function () { }
                                        });

                                    }
                                },
                                ],
                            };
                        });

                        setTimeout(function () {

                            //   $("#myTable_filter").width($("#myTable").width());

                        }, 300);

                        var selects = $('.selectstatus');
                        selects.each(function () {
                            var id = $(this).data("id");

                            console.log("ciaone", $(this).data("id"));

                            $(this).on("ionChange", function (opt) {


                                console.log(opt);

                                //$(".spin").show();
                                pointerVue.showSpinner = true;
                                pointerVue.$root.$children[0].showProgress = true;

                                $.ajax({
                                    url: pointerVue.getUrlDomainApi("updateStatusApi"),
                                    data: {
                                        "id": id,
                                        "hour": this.value,
                                        "username": window.uname,
                                        "cid": window.cid,
                                        "is_su": window.is_su,
                                        "is_admin": window.is_admin,

                                    },
                                    success: function () {
                                       // window.table._fnDraw();
                                       window.table.ajax.reload();
                                    }
                                });

                            });

                        });

                        $(".downloadspinner").hide();

                    }

                });

                //START SETUP
                function throttle(f, delay) {
                    var timer = null;
                    return function () {
                        var context = this,
                            args = arguments;
                        clearTimeout(timer);
                        timer = window.setTimeout(function () {
                            f.apply(context, args);
                        },
                            delay || 500);
                    };
                }
                window.loading = 0;
                $("div.dataTables_filter input").unbind();
                $('div.dataTables_filter input').keyup(throttle(function () {
                    window.table.ajax.reload();

                }));
                $('#myTable_wrapper').on('scroll', function () {
                    if ($(this).scrollTop() + $(this).innerHeight() + 50 >= $(this)[0].scrollHeight) {
                        if (window.loading == 0) {
                            $(".spin").show();
                            var valori = window.valori;
                            valori.start = window.start;

                            $.ajax({
                                url: pointerVue.getUrlDomainGo("getListingRSA") + '?v_user=' + v_token + '&id_azienda=' + window.cid,
                                data: window.valori,
                                success: function (data) {
                                    $(".spin").hide();
                                    window.loading = 0;
                                    window.start += window.perpage;

                                    for (let [index, val] of data.data.entries()) {
                                        console.log(index, val);
                                        window.table.row.add(val).draw(false);
                                        window.dataset.push(val);
                                    }
                                    console.log(data.data);
                                },
                            });
                        }
                        window.loading = 1;
                    }
                });
                //END

                var search = $(".dataTables_length").clone(true);
                search.attr("id", "final_search");
                $(".table_paginate").prepend(search);

                $("#myTable_wrapper .row .col-md-6").first().remove();

                $("#myTable_wrapper .row .col-md-6").first().removeClass("col-md-6");

                //alert (pathLocal);

                var sel = ` <div title="Filtro Stato">  <ion-item>
                <ion-select   data-id="0"  value="0" class="selectstatusall" interface="action-sheet" placeholder=" "><ion-select-option  value='-1'>Tutti</ion-select-option><ion-select-option  value='0'>In attesa</ion-select-option><ion-select-option  value='1'>Confermato</ion-select-option><ion-select-option  value='2'>Disdetto cliente</ion-select-option><ion-select-option  value='3'>Disdetto ammin.</ion-select-option></ion-select></ion-item> </div>
                  `;


                var sel2 = "";


                $("#myTable_filter").find("label").remove();


                $("#myTable_filter").prepend(`
                <div class="cont_buttons">

                    <ion-icon class='dayleft' ios="ios-arrow-dropleft" style="z-index:-2;display:none;cursor:pointer;font-size:32px;" md="md-arrow-dropleft"></ion-icon>

                
                    <ion-icon  class='dayright' style="display:none;font-size:32px;cursor:pointer;" ios="ios-arrow-dropright" md="md-arrow-dropright"></ion-icon>

                    <img src='` + pathLocal + `calendario-smartbook.png' class="calendarButton" title="Filtro Data" />

                    <img src='` + pathLocal + `refresh-smartbook.png' class="refreshButton" title="Aggiorna" />
                    ` + sel + sel2 + `

                </div>
                <div><img src='` + pathLocal + `ricerca-smartbook.png' style='border:0!important;' class='selricerca' title='Ricerca' /></div>

            `);



                $(".calendarButton").on("click", function (opt) {
                    console.log(opt);

                    console.log("CALENDARBUTTON");

                    var dateTest = new Date();

                    dateTest = dateTest.setDate(dateTest.getDate());
                    const dateTest2 = new Date(dateTest).toJSON().slice(0, 10);

                    console.log("DATE: " + dateTest2) //2015-07-23

                    var elements = dateTest2.split("-");


                    console.log("ELEMENTS: ", elements) //2015-07-23

                    var dateStart = elements[0] + "-" + elements[1] + "-01";

                    console.log("DATE START: ", dateStart) //2015-07-23

                    var dayEnd = new Date(2022, 10, 0).getDate();

                    var dateEnd = elements[0] + "-" + elements[1] + "-" + dayEnd;


                    console.log("DATE END: ", dateEnd) //2015-07-23




                    pointerVue.dataDa = window.currDay;

                    pointerVue.dataA = window.currDayTo;


                    $(".contpopupdate").show();

                    pointerVue.$modal.show('popupDate');


                });





                $(".selectstatusall").on("ionChange", function (opt) {
                    console.log(opt);
                    //$(".spin").show();
                    window.curStatus = this.value;

                    pointerVue.showSpinner = true;
                    pointerVue.$root.$children[0].showProgress = true;

                    window.table.ajax.reload();

                });

                $(".selricerca").on("click", function (opt) {
                    console.log(opt);

                    pointerVue.textSearch = "";

                    $(".contpopupsearch").show();

                    pointerVue.$modal.show('popupSearch');

                });


                $('body').on('click', '.paginate_button', function () {
                    $(".spin").show();

                });

                $('body').on('click', '.refreshButton', function () {

                    pointerVue.showSpinner = true;
                    pointerVue.$root.$children[0].showProgress = true;

                    window.table.ajax.reload();

                });

                $('body').on('click', '.contListBookingGenNew tr', function () {

                    setTimeout(() => {

                        var last_id_sel = $("tr.active-row").find(".id_row").attr("data-id");

                        var id_sel = $(this).find(".id_row").attr("data-id");

                        $("tr").removeClass("active-row");

                        var set_cls_active = 0;

                        if (last_id_sel == "undefined") {
                            set_cls_active = 1;
                        } else {
                            if (last_id_sel != id_sel) {
                                set_cls_active = 1;
                            }
                        }

                        if (set_cls_active == 1) {

                            $(this).addClass("active-row");

                            window.id_par_sel = $("tr.active-row").find(".id_row").attr("data-id");

                            console.log("ID: ", window.id_par_sel);

                            pointerVue.hideShowButtons(true);

                        } else {

                            pointerVue.hideShowButtons(false);

                        }

                    }, 100);

                });

                // }

                //setup ionic calendar top
                setTimeout(function () {

                    $("body").on("ionChange", ".dateBook", function (opt) {

                        console.log(opt);
                        var date = this.value;
                        //var dateMysql = date.toJSON().slice(0, 19).replace('T', ' ')   ;
                        dateMysql = date.substr(0, 10);

                        console.log(dateMysql);

                        $.ajax({
                            url: "{{ route('smartbook.updateDate') }}",
                            data: {
                                "id": $(this).data("id"),
                                "date": dateMysql
                            },
                        });

                    });


                    $(".selectpianiall").on("ionChange", function (opt) {
                        console.log(opt);
                        $(".spin").show();
                        window.curPiano = this.value;

                        window.table.fnPageChange(0);
                        window.table._fnDraw();

                    });

                    window.currDay = date;
                    window.currDayTo = date;

                }, 600);

                window.parentiAddedEvents = true;

            });

        },

        hideShowButtons: async function (v_enable) {

            console.log(v_enable);


            // verifico quanti checkbox sono selezionati
            var cntChkSel = 0;
            $('.val_status').each(function () {

                var is_select = 0;

                var chk = $(this).attr("aria-checked");

                if (chk == "true") {
                    is_select = 1;
                }

                if (parseInt(is_select) == 1) {
                    cntChkSel = cntChkSel + 1;
                }

            });

            setTimeout(() => {

                var pulsantis = [];

                // if (v_enable == false) {

                //     if (cntChkSel <= 1) {

                //         pulsantis = [

                //             {
                //                 text: "Add",
                //                 icon: "mdi-home-circle",
                //                 image: "https://app.emtool.eu/public/_lib/img/icon-add-smartbook.png",
                //                 link: "/settings",
                //                 id: "btn_add",
                //                 disabled: false,
                //                 size: 30,
                //                 title: "Aggiungi"
                //             },

                //         ];

                //     }

                //     if (cntChkSel > 1) {

                //         pulsantis = [

                //             {
                //                 text: "Add",
                //                 icon: "mdi-home-circle",
                //                 image: "https://app.emtool.eu/public/_lib/img/icon-add-smartbook.png",
                //                 link: "/settings",
                //                 id: "btn_add",
                //                 disabled: false,
                //                 size: 30,
                //                 title: "Aggiungi"
                //             },

                //             {
                //                 text: "Unisci",
                //                 icon: "mdi-home-circle",
                //                 image: "https://app.emtool.eu/public/_lib/img/union-parents-smartbook.png",
                //                 class: "inverted",
                //                 link: "/info",
                //                 id: "btn_unisci",
                //                 disabled: false,
                //                 title: "Unisci",
                //                 size: 20,

                //             },

                //         ];

                //     }

                // }

                // if (v_enable == true) {

                //     if (cntChkSel <= 1) {

                //         pulsantis = [

                //             {
                //                 text: "Add",
                //                 icon: "mdi-home-circle",
                //                 image: "https://app.emtool.eu/public/_lib/img/icon-add-smartbook.png",
                //                 link: "/settings",
                //                 id: "btn_add",
                //                 disabled: false,
                //                 size: 30,
                //                 title: "Aggiungi"
                //             },

                //             {
                //                 text: "Edit",
                //                 icon: "mdi-home-circle",
                //                 image: "https://app.emtool.eu/public/_lib/img/icon-edit-smartbook.png",
                //                 link: "/info",
                //                 id: "btn_edit",
                //                 disabled: false,
                //                 size: 30,
                //                 title: "Modifica"

                //             },

                //             {
                //                 text: "Delete",
                //                 icon: "mdi-home-circle",
                //                 image: "https://app.emtool.eu/public/_lib/img/icon-delete-smartbook.png",
                //                 link: "/info",
                //                 id: "btn_delete",
                //                 disabled: false,
                //                 size: 25,
                //                 title: "Elimina"

                //             },

                //         ];
                //     }

                //     if (cntChkSel > 1) {

                //         pulsantis = [

                //             {
                //                 text: "Add",
                //                 icon: "mdi-home-circle",
                //                 image: "https://app.emtool.eu/public/_lib/img/icon-add-smartbook.png",
                //                 link: "/settings",
                //                 id: "btn_add",
                //                 disabled: false,
                //                 size: 30,
                //                 title: "Aggiungi"

                //             },

                //             {
                //                 text: "Edit",
                //                 icon: "mdi-home-circle",
                //                 image: "https://app.emtool.eu/public/_lib/img/icon-edit-smartbook.png",
                //                 link: "/info",
                //                 id: "btn_edit",
                //                 disabled: false,
                //                 size: 30,
                //                 title: "Modifica"

                //             },

                //             {
                //                 text: "Delete",
                //                 icon: "mdi-home-circle",
                //                 image: "https://app.emtool.eu/public/_lib/img/icon-delete-smartbook.png",
                //                 link: "/info",
                //                 id: "btn_delete",
                //                 disabled: false,
                //                 size: 25,
                //                 title: "Elimina"

                //             },

                //             {
                //                 text: "Unisci",
                //                 icon: "mdi-home-circle",
                //                 image: "https://app.emtool.eu/public/_lib/img/union-parents-smartbook.png",
                //                 class: "inverted",
                //                 link: "/info",
                //                 id: "btn_unisci",
                //                 disabled: false,
                //                 title: "Unisci",
                //                 size: 20

                //             },

                //         ];

                //     }

                // }

                this.$root.$children[0].bnavbuttons = pulsantis;

            }, 100);

        },

        setupButtonsGrid: async function () {
            setTimeout(() => {
                // console.log ("here we go" , this.$root.$children[0].drawer ) ;
                //window.App.$children[0].setToolbar();
                //   this.$root.$children[0].setToolbar();

                var pulsantis = [];

                // var pulsantis = [

                //     {
                //         text: "Add",
                //         icon: "mdi-home-circle",
                //         image: "https://app.emtool.eu/public/_lib/img/icon-add-smartbook.png",
                //         link: "/settings",
                //         id: "btn_add",
                //         disabled: false,
                //         size: 30,
                //         title: "Aggiungi"

                //     },

                //     {
                //         text: "Edit",
                //         icon: "mdi-home-circle",
                //         image: "https://app.emtool.eu/public/_lib/img/icon-edit-smartbook.png",
                //         link: "/info",
                //         id: "btn_edit",
                //         disabled: false,
                //         size: 30,
                //         title: "Modifica"

                //     },

                //     {
                //         text: "Delete",
                //         icon: "mdi-home-circle",
                //         image: "https://app.emtool.eu/public/_lib/img/icon-delete-smartbook.png",
                //         link: "/info",
                //         id: "btn_delete",
                //         disabled: false,
                //         size: 25,
                //         title: "Elimina"

                //     },

                //     {
                //         text: "Dashboard",
                //         icon: "mdi-home-circle",
                //         link: "/dash",
                //         id: "btn_exit",
                //         disabled: false,
                //     },

                // ];
                //window.App.$children[0].bnavbuttons = pulsantis;
                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {

                }, 100);

            }, 100);
        },

        setupButtons: async function () {
            setTimeout(() => {
                // console.log ("here we go" , this.$root.$children[0].drawer ) ;
                //window.App.$children[0].setToolbar();
                //   this.$root.$children[0].setToolbar();

                var pulsantis = [

                    {
                        text: "Dashboard",
                        icon: "mdi-home-circle",
                        link: "/qrconfirm",
                        id: "btn_qrcode",
                        image: "https://app.smart-book.it/public/smartbook/ICON_3.png",
                        disabled: false,
                    },

                    {
                        text: "Settings",
                        icon: "mdi-home-circle",
                        image: "https://app.smart-book.it/public/_lib/img/icons8-settings-64.png",
                        class: "inverted",
                        link: "/settings",
                        id: "btn_settings",
                        disabled: false,
                    },

                    {
                        text: "Info",
                        icon: "mdi-home-circle",
                        image: "https://app.smart-book.it/public/_lib/img/icons8-informazioni-482.png",
                        class: "inverted",
                        link: "/info",
                        id: "btn_info",
                        disabled: false,
                    },

                    {
                        text: "Pdf",
                        icon: "mdi-home-circle",
                        image: "https://app.smart-book.it/public/_lib/img/sb-export-pdf-50.png",
                        class: "inverted",
                        link: "/pdf",
                        id: "btn_pdf",
                        disabled: false,
                    },

                ];

                //window.App.$children[0].bnavbuttons = pulsantis;
                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {

                }, 100);

            }, 100);
        },


        syncProfile: async function () {
            /*  var token = window.$cookies.get("token");
            var system = window.$cookies.get("system"); */

            //  this.data.days = response.data ;
        },

        btn_add_tms_click() {
            console.log(1);
        },
        /*          onClick() {
      console.log('a phone number was clicked');
    }
,
 */
        reply(par) {
            console.log(par);
        },

        reset() {
            this.$refs.form.reset();
        },
        resetValidation() {
            this.$refs.form.resetValidation();
        },

        createPdf: async function () {

            var that = this;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            // valori.currDayTo = window.currDayTo;
            // valori.curStatus = window.curStatus;
            // valori.curPiano = window.curPiano;

            var response = await apibookingrsa.getPdfPrenotazioni(
                "ZmFnb3N0b25p",
                3165,
                window.currDay,
                window.currDayTo,
                window.curStatus,
                window.curPiano,
                ""
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getPdfPrenotazioni", res);

                try {

                    console.log(res.data.Result);

                    window.open(res.data.Result, '_blank');

                } catch (error) {
                    console.log(error);
                }

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile esportare il pdf");
                console.log("response", response);

            }

            );

        },

        deleteRow: async function () {

            var v_token = window.$cookies.get("token");

            var that = this;

            that.$swal
                .fire({
                    title: "Elimina",
                    html: "Vuoi eliminare l'elemento selezionato ?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "<img title='Conferma' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_confirm3_smartbook.png' />",
                    cancelButtonText: "<img title='Annulla' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_cancel3_smartbook.png' />",
                    customClass: {
                        cancelButton: 'order-1',
                        confirmButton: 'order-2',
                    },
                })
                .then(async function (result) {

                    if (result.value == true) {

                        that.showSpinner = true;
                        that.$root.$children[0].showProgress = true;

                        var response = await apiparenti.deleteParenti(
                            window.id_par_sel,
                            v_token


                        ).then((res) => {

                            that.$root.$children[0].showProgress = false;
                            console.log("res from deleteParenti", res);

                            that.$swal({
                                icon: "success",
                                text: "Dati eliminati correttamente",
                                showConfirmButton: false,
                                timer: 2000
                            });

                            setTimeout(() => {

                                that.dialogMsg = false;
                                that.setupButtons();

                                // window.table._fnDraw();
                                // that.hideShowButtons(false);

                                window.location.reload();

                            }, 200);

                        }).catch(err => {
                            that.$root.$children[0].showProgress = false;
                            console.log(err);
                            var msg = err.response.data.Error;

                            that.$swal({
                                icon: "error",
                                text: msg,
                                showConfirmButton: false,
                                timer: 8000
                            });
                            console.log("ERrori", "Non è stato possibile eliminare i dati");
                            console.log("response", response);

                        }

                        );

                    }

                });

        },

    },
};
</script>

<style scoped>
.titlenewrow {
    justify-content: center;
    padding-top: 25px;
    padding-bottom: 30px;
    font-weight: bolder;
}

.v-sheet.v-card:not(.v-sheet--outlined) {
    box-shadow: none;
}
</style>

<style>
.clColumnDate {
    display: flex;
}

.clColumnDateMobile {
    display: none;
}

.contListBookingGenNew .calendarButton {
    cursor: pointer;
    width: 25px;
}


.contListBookingGenNew .clHeaderOraData {
    width: 91px !important;
    text-align: right !important;
}






.contListBookingGenNew .clHeaderPersone {
    text-align: right !important;
    padding-right: 7px !important;
}

.contListBookingGenNew .clColumnPersone {
    text-align: right !important;
}


.contListBookingGenNew .clHeaderTelefono {
    width: 80px !important;
    text-align: right !important;
}


.contListBookingGenNew .clTextArea {
    border: 1px solid #0000001c;
    min-height: 100px;
    width: 270px;
}

.contListBookingGenNew .clTextAreaColNumPersone {
    resize: none !important;
    height: 11px !important;
    overflow-y: hidden !important;
    text-align: right !important;
    user-select: none !important;
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    transform: translateX(55px);
}

.clTextAreaColNumPersone:focus {
    border-color: #ffffff !important;
    outline: none !important;
}




.contListBookingGenNew .clTextAreaColTelefono {
    resize: none !important;
    height: 11px !important;
    overflow-y: hidden !important;
    text-align: right !important;
    user-select: none !important;
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    width: 80px !important;
}

.clTextAreaColTelefono:focus {
    border-color: #ffffff !important;
    outline: none !important;
}








.contListBookingGenNew .clTextAreaColEMail {
    resize: none !important;
    height: 11px !important;
    overflow-y: hidden !important;
    user-select: none !important;
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
}

.clTextAreaColEMail:focus {
    border-color: #ffffff !important;
    outline: none !important;
}





.pay {
    display: flex;
    justify-content: center;
    padding-top: 10px;
    color: #f77002;

}

.pay a {
    color: #f77002;

    -webkit-transition: background-color 2s ease-out;
    -moz-transition: background-color 2s ease-out;
    -o-transition: background-color 2s ease-out;
    transition: background-color 2s ease-out;

}

.pay a:hover {
    color: #d56e1a;
}

.plan {
    margin-top: 20px;
    font-size: 12px;
    display: flex;
    justify-content: center;

}

.policy {
    text-align: center;
    margin-top: 30px;
}

.version {
    font-size: 10px;
    margin-top: 25px;
    text-align: center;
}

.version span {
    font-size: 13px;
}

.titleproduct {
    font-size: 16px;
    font-size: 16px;
    color: #rgb(245, 207, 46);
    font-weight: 800;

    text-align: center;
    margin-top: 40px;

}

.Absolute-Center {

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    bottom: 0;
    height: 100px;
    left: 0;
    margin: auto;
    position: absolute;
    top: 0;
    right: 0;
    width: 300px;
}

.logo {
    text-align: center;
}

.table-responsive {
    max-width: 100vw;
}

ion-items {
    min-width: 60px;

}

ion-items ion-datetime {
    margin-left: 25px;
}

.colcontTable .col-sm-12 {
    padding:
        0px;
}

.selectpianiall {
    max-width: 21px;
    min-height: 22px;
    margin-left: 0px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    /* border-radius: 12px; */
    /* min-height: 12px; */
    /* transform: translateY(-2px); */
    background-image: url(/smartb/img/FiltroNucleo.png);
    /* padding: 1px 11px; */
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    transform: translateY(2px);
}

.contListBookingGenNew #myTable {
    margin-right: 3px;
}

.contListBookingGenNew table.dataTable.dtr-inline.collapsed>tbody>tr>td.dtr-control,
.contListBookingGenNew table.dataTable.dtr-inline.collapsed>tbody>tr>th.dtr-control {
    padding-left: 20px !important;
}

.contListBookingGenNew th {
    padding-left: 5px !important;
}

.contListBookingGenNew ion-datetime.dateBook {
    margin-left: 16px;
}

.contListBookingGenNew .table-responsive {
    padding: 0px;
    min-width: 80vw;
    max-width: 96vw;
}

.contListBookingGenNew #myTable_filter {
    position: fixed !important;
    left: 0px !important;
    min-width: 100% !important;
    z-index: 3 !important;
    background: #ffffff !important;
    border-color: #de7d37 !important;
    top: 64px;
}

.contListBookingGenNew div.dataTables_wrapper div.dataTables_filter input {
    /* margin-top:5px!important */
}

.cl-date {
    font-size: 10px;
    color: gray;
}


.contListBookingGenNew #myTable_wrapper {
    min-height: 84vh !important;
    max-width: 100vw !important;
    margin-top: -41px !important;
}

.contListBookingGenNew #myTable {
    max-width: 100vw !important;
}

.contListBookingGenNew .text_title {
    background-color: white !important;
    color: black !important;
    font-weight: bold !important;
    padding-top: 4px !important;
    padding-bottom: 4px !important;
    padding-left: 0px !important;
    font-size: 10px !important;
    min-width: 82vw !important;
}

.contListBookingGenNew .val_status {
    margin-left: 0px;
}

.contListBookingGenNew .selricerca {
    max-width: 31px;
    min-height: 31px;
    margin-left: 9px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
    margin-right: 14px !important;
}


@media screen and (max-width: 960px) {

    .contListBookingGenNew .val_status {
        margin-left: 10px;
    }

    .contListBookingGenNew #myTable {
        /* margin-top:54px; */
    }

    .contListBookingGenNew #myTable_filter {
        top: 56px;
        position: fixed;

    }

}

@media screen and (max-width: 1024px) {

    .contIcon {
        display: inherit;
    }

    .contListBookingGenNew .table-responsive {
        padding: 0px;
        min-width: 80vw;
        max-width: 100vw;
        left: 0px;
        position: fixed;

    }

    .contListBookingGenNew #myTable {
        max-width: 100vw;
        margin-right: 0px;
        min-width: 100vw;
        position: relative;
        /*   left: 12px; */
        width: 100vw !important;

    }

    .clColumnDate {
        display: none;
    }

    .clColumnDateMobile {
        display: block;
    }

}
</style>
