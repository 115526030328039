import axios from 'axios'

//import { isProduction, env } from '@/utils/env'

//var httpOk = null ;

class APIBookingRSA {

  getUrlDomain(nome_metodo)
  {

    let result = "";

    if (location.hostname == "localhost") {
        result = location.protocol + "//emtool.local/";
    }

    if (location.hostname != "localhost") {

        result = location.protocol + "//" + location.hostname + "/";

    }

    return result + "public/api/smartbook/" + nome_metodo;


  }

  getUrlDomain2(nome_metodo)
  {

    let result = "";

    if (location.hostname == "localhost") {
        result = location.protocol + "//emtool.local/";
    }

    if (location.hostname != "localhost") {

        result = location.protocol + "//" + location.hostname + "/";

    }

    return result + nome_metodo;


  }

  async getPdfPrenotazioni(v_user, id_azienda, v_today, v_todayTo, curStatus, curPiano, search)
  {

    let formData = new FormData();

    formData.append("v_user",  v_user);
    formData.append("id_azienda",  id_azienda);
    formData.append("v_today",  v_today);
    formData.append("v_todayTo",  v_todayTo);
    formData.append("curStatus",  curStatus);
    formData.append("curPiano",  curPiano);
    formData.append("search",  search);

    return axios.post(this.getUrlDomain("getPdfPrenotazioni") , formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }

  async checkBookingHour(v_id, v_data)
  {

    let formData = new FormData();

    formData.append("v_id",  v_id);
    formData.append("v_data",  v_data);

    return axios.post(this.getUrlDomain("checkHourBookingRistorazione") , formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }


  async getHoursAvailable(username, cid, is_su, bookingtype, currDay, currDayTo, curStatus)
  {

    let formData = new FormData();

    formData.append("username",  username);
    formData.append("cid",  cid);
    formData.append("is_su",  is_su);
    formData.append("bookingtype",  bookingtype);
    formData.append("currDay",  currDay);
    formData.append("currDayTo",  currDayTo);
    formData.append("curStatus",  curStatus);

    return axios.post(this.getUrlDomain2("getHoursAvailable") , formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }



}

export default new APIBookingRSA({
  url: "http://emtool.local/public/api/smartbook/"
})
